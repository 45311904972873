import whiteBed from "../../../images/white-bed.png" // eslint-disable-line

import arrow from "../../../images/link-arrow.png"
import phone from "../../../images/icon-phone-black.png"

import grid from "../../../images/icon-grid.png"
import gridOrange from "../../../images/icon-grid-orange.png"

import map from "../../../images/icon-find-pub.png"
import mapOrange from "../../../images/icon-find-pub-orange.png"

export default theme => ({
  results: {
    "& p": {
      "& button": {
        display: "inline-block",
        border: "none",
        backgroundColor: "transparent",
        borderBottom: "2px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main,
        padding: "0 0 4px",
        fontFamily: theme.headerFont,
        textTransform: "uppercase",
        letterSpacing: "0.04em",
        transition: "color .3s ease-in-out, border-color .3s ease-in-out",
        outline: "none !important",
        marginLeft: 12,
        "&:hover, &:focus": {
          color: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    "& .list-filter": {
      display: "block",
      textAlign: "center",
      marginBottom: 64,
      "& span": {
        fontSize: 18,
        letterSpacing: "0.068em",
        fontFamily: "Proxima Nova",
        fontWeight: 600,
        display: "inline-block",
        textTransform: "uppercase",
        lineHeight: "32px",
        paddingLeft: 32,
        backgroundSize: 32,
        backgroundPosition: "center left",
        backgroundRepeat: "no-repeat",
        margin: "0 12px",
        cursor: "pointer",
        opacity: 1,
        color: theme.palette.primary.main,
        '&[data-active="true"]': {
          color: theme.palette.common.black,
          opacity: 0.5,
        },
        "&.grid": {
          backgroundImage: "url(" + gridOrange + ")",
          '&[data-active="true"]': {
            backgroundImage: "url(" + grid + ")",
          },
        },
        "&.map": {
          backgroundImage: "url(" + mapOrange + ")",
          '&[data-active="true"]': {
            backgroundImage: "url(" + map + ")",
          },
        },
      },
    },
  },
  pubListing: {
    position: "relative",
    marginBottom: 36,
  },
  pubListingTheme: {
    ...theme.pubListingTheme,
  },
  Card: {
    boxShadow: "0 3px 32px rgba(0,0,0,0.16)",
    backgroundColor: theme.palette.secondary.main,
  },
  CardUpper: {
    position: "relative",
  },
  CardBottom: {
    padding: "20px 20px 30px",
    borderBottom: "2px solid " + theme.palette.secondary.contrastText,
    height: 200,
    [theme.breakpoints.up("md")]: {
      padding: 30,
    },
  },
  CardName: {
    margin: 0,
    fontSize: 18,
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    lineHeight: 1.5,
    color: theme.palette.secondary.contrastText,
    fontFamily: theme.subHeaderFont,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
    },
  },
  CardLocation: {
    marginTop: 5,
    fontStyle: "italic",
    fontSize: 14,
    lineHeight: 2,
    letterSpacing: "0.056em",
    marginBottom: 12,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      marginBottom: 20,
    },
  },
  CardPhone: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 1.1,
    letterSpacing: "0.06em",
    fontFamily: theme.subHeaderFont,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    "& img": {
      height: 14,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        height: 18,
      },
    },
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  CardLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 20px",
    color: theme.palette.primary.main,
    fontFamily: theme.headerFont,
    fontSize: 14,
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: "normal",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    "& img": {
      height: 15,
    },
  },
  CardInfo: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 60,
    background:
      "linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 40%,rgba(0,0,0,0) 100%);",
    width: "100%",
    padding: "25px 20px 0",
    [theme.breakpoints.up("md")]: {
      padding: "25px 30px 0",
    },
  },
  Tenancy: {
    backgroundImage: "url(" + whiteBed + ")",
    backgroundPosition: "center left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 15,
    lineHeight: "15px",
    paddingLeft: 30,
    fontSize: 12,
    color: theme.palette.secondary.contrastText,
    margin: 0,
    display: "inline-block",
    fontStyle: "italic",
    fontFamily: theme.bodyFont,
    fontWeight: "normal",
    [theme.breakpoints.up("lg")]: {
      height: 18,
      fontSize: 15,
      lineHeight: "18px",
      paddingLeft: 38,
    },
  },
  Map: {
    display: "block",
    height: 480,
    boxShadow: "0 3px 8px rgb(0 0 0 / 16%)",
    [theme.breakpoints.up("md")]: {
      height: 620,
    },
    [theme.breakpoints.up("lg")]: {
      height: 768,
    },
    "& .mapboxgl-canary": {},
    "& .mapboxgl-canvas-container": {
      "& canvas": {},
    },
    "& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, & .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip, & .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip": {
      borderTopColor: "#f1e9e4",
    },
    "& .mapboxgl-popup-anchor-top .mapboxgl-popup-tip, & .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, & .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip": {
      borderBottomColor: "#f1e9e4",
    },
    "& .mapboxgl-popup": {
      maxWidth: "280px !important",
      "& .mapboxgl-popup-tip": {
        opacity: 0,
      },
      "& .mapboxgl-popup-content": {
        backgroundColor: "#F1E9E4",
        padding: 0,
        borderRadius: 0,
        boxShadow: "4px 16px 16px rgba(0,0,0,0.16)",
        width: 280,
        minWidth: 280,
        "& img": {
          objectFit: "cover",
          objectPostion: "center",
          aspectRatio: "280/180",
          display: "block",
          width: "100%",
        },
        "& h3": {
          paddingInline: 24,
          marginBottom: 6,
          fontSize: 16,
          fontWeight: "bold",
          lineHeight: 1.5,
          letterSpacing: "0.04em",
          textTransform: "uppercase",
          fontFamily: theme.headerFont,
          position: "relative",
          zIndex: 1,
          background:
            "linear-gradient(180deg, rgba(241,233,228,0) 0%, rgba(241,233,228,.45) 45%, rgba(241,233,228,.94) 75%, rgba(241,233,228,1) 100%)",
          paddingTop: 40,
          color: "#0E0E0E",
          marginTop: -64,
        },
        "& p": {
          paddingInline: 24,
          marginTop: 0,
          marginBottom: 32,
          color: "#0E0E0E",
          fontStyle: "italic",
          fontSize: 14,
          fontFamily: theme.bodyFont,
        },
        "& a": {
          fontFamily: theme.headerFont,
          fontWeight: "bold",
          color: "#A15A38",
          letterSpacing: "-0.008em",
          fontSize: 16,
          lineHeight: 1,
          padding: 14,
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          borderTop: "1px solid #A15A38",
          "&::after": {
            content: "''",
            backgroundImage: `url(${arrow})`,
            backgroundSize: 33,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 34,
            height: 16,
            display: "block",
            marginLeft: 4,
          },
        },
        "& button": {
          color: "black",
          fontSize: 32,
          padding: 0,
          lineHeight: 1,
          width: 32,
        },
      },
    },
    "& .mapboxgl-control-container": {
      "& .mapboxgl-ctrl-top-left": {},
      "& .mapboxgl-ctrl-top-right": {},
      "& .mapboxgl-ctrl-bottom-right": {},
      "& .mapboxgl-ctrl-bottom-right": {},
    },
  },
})
