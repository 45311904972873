import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CgbAccommodationIntroBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  var count = 0

  return (
    <div
      className={`accommodation-intro ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.accommodationIntro} ${classes.accommodationIntroTheme}`}
    >
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} md={false}>
          <div className="extras">
            From <span>£{props.parentAttributes.price}</span> Per Night (INC
            VAT)
          </div>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          {blocks[0]}
          {blocks[1]}
        </Grid>
        <Grid item xs={12} md={7}>
          <ul>
            {props.parentAttributes.amenities.map(amenity => {
              let image = null
              if (amenity.iconUriSharp) {
                if (amenity.iconUriSharp.childImageSharp) {
                  image = (
                    <Img
                      fluid={amenity.iconUriSharp.childImageSharp.fluid}
                      alt={amenity.name}
                    />
                  )
                } else {
                  // Gifs do not have a childImageSharp
                  image = (
                    <div className="gatsby-image-wrapper">
                      <div style={{ paddingBottom: "100%" }} />
                      <img
                        src={amenity.iconUriSharp.publicURL}
                        alt={amenity.name}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                      />
                    </div>
                  )
                }
              } else {
                // console.log(props.attributes.height);
                // This is a preview image
                image = (
                  <div className="gatsby-image-wrapper">
                    <div style={{ paddingBottom: "100%" }} />
                    <img
                      src={amenity.iconUri}
                      alt=""
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center center",
                      }}
                    />
                  </div>
                )
              }
              count = count + 1
              return (
                <li key={"amenity-" + count}>
                  {image} {amenity.name}
                </li>
              )
            })}
          </ul>
        </Grid>
        <Grid item xs={12}>
          {blocks[2]}
        </Grid>
      </Grid>
    </div>
  )
}

export const CgbAccommodationIntroBlockFragment = graphql`
  fragment CgbAccommodationIntroBlock on wp_Block {
    ... on wp_CgbAccommodationIntroBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CgbAccommodationIntroBlockPreview = `
    ... on CgbAccommodationIntroBlock {
        name
        attributes {
            className
        }
    }
`
