import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Link from "../../Link"
import Img from "gatsby-image"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const CgbBannerMessageBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // if( ! props.attributes.mediaUrl) return null;
  let image = null

  // Use the gatsby image if available
  if (props.attributes.mediaUrlSharp) {
    if (props.attributes.mediaUrlSharp.childImageSharp) {
      image = (
        <Img
          className="desktop-bg"
          fluid={props.attributes.mediaUrlSharp.childImageSharp.fluid}
          alt=""
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          className="desktop-bg"
          src={props.attributes.mediaUrlSharp.publicURL}
          alt=""
        />
      )
    }
  } else {
    // This is a preview image
    image = (
      <div className="gatsby-image-wrapper desktop-bg">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={props.attributes.mediaUrl}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    )
  }
  let mobileImage = null

  // Use the gatsby image if available
  if (props.attributes.mediaUrlMobileSharp) {
    if (props.attributes.mediaUrlMobileSharp.childImageSharp) {
      mobileImage = (
        <Img
          className="mobile-bg"
          fixed={props.attributes.mediaUrlMobileSharp.childImageSharp.fixed}
          alt=""
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      mobileImage = (
        <img
          className="mobile-bg"
          src={props.attributes.mediaUrlMobileSharp.publicURL}
          alt=""
        />
      )
    }
  } else {
    // This is a preview image
    mobileImage = (
      <div className="gatsby-image-wrapper mobile-bg">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={props.attributes.mediaUrlMobile}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    )
  }

  const data = useStaticQuery(graphql`
    query weddingButtons {
      wp {
        themeOptions {
          ThemeOptions {
            weddingLinks {
              cta1 {
                url
                title
              }
              cta2 {
                url
                title
              }
              cta3 {
                url
                title
              }
              cta4 {
                url
                title
              }
            }
          }
        }
      }
    }
  `)

  let weddingButtons = data?.wp?.themeOptions?.ThemeOptions?.weddingLinks

  return (
    <div
      className={`banner-message ${
        props.attributes.className ? props.attributes.className : ""
      } ${useClasses.bannerMessage} ${useClasses.bannerMessageTheme}`}
    >
      {image}
      {mobileImage}
      {blocks.map(block => {
        return block
      })}
      {props.pageContext.wedding && (
        <div
          className={`wedding-ctas ${useClasses.weddingCTAs} ${
            scrollPosition > 796 ? "scrolled" : ""
          }`}
        >
          {weddingButtons?.cta1 && (
            <Link to={weddingButtons.cta1.url}>
              {weddingButtons.cta1.title}
            </Link>
          )}
          {weddingButtons?.cta2 && (
            <Link to={weddingButtons.cta2.url}>
              {weddingButtons.cta2.title}
            </Link>
          )}
          {weddingButtons?.cta3 && (
            <Link to={weddingButtons.cta3.url}>
              {weddingButtons.cta3.title}
            </Link>
          )}
          {weddingButtons?.cta4 && (
            <Link to={weddingButtons.cta4.url}>
              {weddingButtons.cta4.title}
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

CgbBannerMessageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const CgbBannerMessageBlockFragment = graphql`
  fragment CgbBannerMessageBlock on wp_Block {
    ... on wp_CgbBannerMessageBlock {
      name
      attributes {
        __typename
        ... on wp_CgbBannerMessageBlockAttributes {
          className
          mediaUrl
          mediaUrlSharp {
            id
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660, maxHeight: 830) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mediaUrlMobile
          mediaUrlMobileSharp {
            id
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 960, height: 595) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBannerMessageBlockPreview = `
    ... on CgbBannerMessageBlock {
        name
        attributes {
            __typename
            ... on CgbBannerMessageBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`
