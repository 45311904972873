import  React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {graphql, useStaticQuery} from "gatsby";
import Link from '../../Link';
import Typography from "@material-ui/core/Typography/Typography";
import DragScroll from "react-dragscroll";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbAccommodationOtherRoomsBlock = (props) => {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query AccommodationOtherRoomsList {
        wp {
          rooms {
            nodes {
              id
              slug
              title
              RoomData {
                listImage {
                  mediaItemUrl
                  mediaItemUrlSharp {
                      id
                      publicURL
                      childImageSharp {
                          fluid(quality: 80) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                }
              }
            }
          }
          themeOptions {
            accommodationPath
          }  
        }
      }
    `);

    var topLink = '';
    var others = [];

    for ( const room of Object.entries(data.wp.rooms.nodes) ) {
        if ( room[1].slug === props.parentAttributes.slug ) {
            topLink = room[1];
        } else {
            if(others.length < 3) {
                others.push(room[1]);
            }
        }
    }

    let bgImage = '';
    if ( topLink?.RoomData?.listImage?.mediaItemUrlSharp ) {
        bgImage = topLink.RoomData.listImage.mediaItemUrlSharp.publicURL;
    } else {
        bgImage = '/images/other-rooms-generic.jpg';
    }
    
    return (
        <div className={`other-rooms ${props.attributes.className ? props.attributes.className : ''} ${classes.accommodationOtherRooms} ${classes.accommodationOtherRoomsTheme}`}>
            <Typography component='h2' variant='h2' color='initial' align='left' >{props.attributes.sectionTitle}</Typography>
            <div className="scrollable">
                <DragScroll className="scroll-inner" height={540} width={100}>
                    <div className="other-rooms">
                        {others.map(room => {
                            return <Link to={'/' + data.wp.themeOptions.accommodationPath + '/' + room.slug + '/'} className="room-link" style={{backgroundImage: 'url(' + room.RoomData.listImage.mediaItemUrlSharp.publicURL + ')'}} key={room.id}>
                                <span className="tag">{props.attributes.tagText}</span>
                                <span className="room-title">{room.title}</span>
                                <span className="cta">{props.attributes.btnText}</span>
                            </Link>
                        })}
                        <Link to={'/' + data.wp.themeOptions.accommodationPath + '/'} className="room-link" style={{backgroundImage: 'url(' + bgImage + ')'}}>
                            <span className="tag">{props.attributes.tagText}</span>
                            <span className="room-title">All Rooms</span>
                            <span className="cta">Explore</span>
                        </Link>
                    </div>
                </DragScroll>
            </div>
        </div>
    )
};

CgbAccommodationOtherRoomsBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbAccommodationOtherRoomsBlockFragment = graphql`
  fragment CgbAccommodationOtherRoomsBlock on wp_Block {
    ... on wp_CgbAccommodationOtherRoomsBlock {
      name
      attributes {
        __typename
        ... on wp_CgbAccommodationOtherRoomsBlockAttributes {
            className
            sectionTitle
            tagText
            btnText
        }
      }
    }
  }`;

export const CgbAccommodationOtherRoomsBlockPreview = `
... on CgbAccommodationOtherRoomsBlock {
  name
  attributes {
    __typename
    ... on CgbAccommodationOtherRoomsBlockAttributes {
      className
      sectionTitle
      tagText
      btnText
    }
  }
}
`;