export default theme => ({
    accommodationFeaturedRooms: {
        padding: '70px 24px 75px',
        width: '100vw',
        marginLeft: '50%',
        transform: 'translateX(-50vw)',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            padding: '110px 24px',
        },
        backgroundColor: theme.palette.tertiary.main,
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            // textTransform: 'uppercase',
            marginBottom: 35,
            letterSpacing: '0.06em',
            backgroundColor: theme.palette.tertiary.contrastText,
        },
        '& p': {
            backgroundColor: theme.palette.tertiary.contrastText,
        },
        '& .con-wrap': {
            [theme.breakpoints.up('md')]: {
                maxWidth: 1120,
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingLeft: '8.333%',
            },
            '& > a': {
                clear: 'both',
                margin: '24px 0 70px',
                display: 'inline-block',
                '& button': {
                    minWidth: 0,
                }
            }
        },
    },
    accommodationFeaturedRoomsTheme: {
        ...theme.accommodationFeaturedRoomsTheme
    },
})