export default theme => ({
  textBackground: {
    position: "relative",
    padding: 30,
    backgroundColor: theme.palette.secondary.main,
    marginInline: "12px",
    [theme.breakpoints.up("md")]: {
      padding: 44,
      marginBlock: "40px",
      marginInline: "auto",
    },
    "& p": {
      fontSize: 16,
      color: theme.palette.secondary.contrastText,
      maxWidth: 530,
      marginInline: "auto",
      marginBlock: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: 25,
      },
    },
  },
})
