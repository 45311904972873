export default theme => ({
    bannerMessage: {
        position: 'relative',
        marginBottom: 36,
        '& > .gatsby-image-wrapper': {
            height: '595px !important',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px) !important',
            zIndex: '1',
            overflow: 'hidden',
            backgroundColor: '#333',
            backgroundPosition: 'center !important',
            backgroundSize: 'cover !important',
            '& > div': {
                paddingBottom: '595px !important',
            },
            '& .video-wrapper': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '100%',
                paddingBottom: '0 !important',
                height: '56vw',
                minHeight: 595,
                minWidth: 1070,
                left: '50%',
                transform: 'translateX(-50%)',
                '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100% !important',
                    height: '100% !important',
                    minHeight: 595,
                    minWidth: 1070,
                },
            },
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '50% !important',
                }
            },
            '&::after': {
                position: 'absolute',
                display: 'block',
                content: '""',
                bottom: '0',
                left: '0',
                right: '0',
                height: 300,
                zIndex: '2',
                background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%,rgba(0,0,0,1) 100%)',
                opacity: '0.3',
                [theme.breakpoints.up('md')]: {
                    height: 340,
                    opacity: '0.51',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.58) 33%,rgba(0,0,0,1) 66%,rgba(0,0,0,1) 100%)',
                },
            },
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            position: 'absolute',
            bottom: 90,
            color: 'white',
            marginBottom: '0',
            zIndex: '3',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '85%'
            },
            [theme.breakpoints.up('md')]: {
                bottom: 50,
            },
        },
    },
    bannerMessageTheme: {
        ...theme.bannerMessageTheme,
    }
})