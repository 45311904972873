import arrow from '../../../images/arrow-next-light.png';

export default theme => ({
    cardRow: {
        position: 'relative',
        marginBottom: 36,
        '& .card': {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                marginTop: 12,
                letterSpacing: '0.032em',
            },
            '& button': {
                justifyContent: 'space-between',
                minWidth: 220,
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    width: 16,
                    height: 16,
                    backgroundImage: 'url('+arrow+')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginTop: 2,
                }
            },
            '& button span': {
                textAlign: 'left',
                letterSpacing: '0.06em',
            },
            '& .con-wrap': {
                [theme.breakpoints.up('lg')]: {
                    padding: 44,
                    '& h1, & h2, & h3, & h4, & h5, & h6': {
                        fontSize: 32,
                        marginBottom: 24,
                    },
                    '& p': {
                        fontSize: 16,
                        marginBottom: 100,
                        lineHeight: 2,
                    }
                },
            }
        },
        '&.has-top-images': {
            '& .card': {
                display: 'flex',
                flexDirection: 'column-reverse',
            }
        },
        '&.has-black-cards': {
            '& .card': {
                backgroundColor: theme.palette.secondary.main,
                '& .con-wrap': {
                    '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                        color: theme.palette.secondary.contrastText,
                    }
                }
            }
        }
    },
    cardRowTheme: {
        ...theme.cardRowTheme,
    }
})