export default theme => ({
  testimonialSliderInner: {
    position: "relative",
    "& .MuiGrid-grid-lg-1": {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
    "& .gatsby-image-wrapper": {
      margin: 2,
      "& > div": {
        aspectRatio: 1,
        paddingBottom: "0px !important",
        [theme.breakpoints.up("md")]: {
          aspectRatio: 1.33,
        },
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginTop: 0,
      marginBottom: 32,
      fontFamily: "Libre Baskerville",
      fontWeight: "normal",
      fontSize: 26,
      [theme.breakpoints.up("md")]: {
        fontSize: 36,
      },
    },
    "& p": {
      fontFamily: "Libre Baskerville",
      fontSize: 13,
      paddingInline: "20px",
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
      },
    },
    "& a": {
      width: "fit-content",
      display: "block",
      margin: "0 auto 32px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "0 !important",
      },
      "& button": {
        minWidth: 174,
        backgroundColor: theme.palette.wedding.button,
        color: "white",
        fontFamily: "Libre Baskerville",
        fontSize: 15,
        fontWeight: "normal",
        padding: 8,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.wedding.button,
          textTransform: "0 0 4px 4px rgba(0,0,0.25)",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 17,
          borderRadius: 6,
          padding: 15,
          minWidth: 244,
        },
      },
    },
  },
})
