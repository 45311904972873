export default theme => ({
    accommodationOtherRooms: {
        // padding: '90px 0',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            // padding: '115px 0 230px',
        },
        '& .scrollable': {
            height: 400,
            overflow: 'hidden',
            width: 'calc(100% +36px)',
            margin: '0 -18px',
            [theme.breakpoints.up('lg')]: {
                width: 'calc(100% + 24px)',
                margin: '50px 0 0',
            },
            '& .scroll-inner': {
                width: '100% !important',
            }
        },
        '& .other-rooms': {
            whiteSpace: 'nowrap',
            '& .room-link': {
                display: 'inline-block',
                marginRight: 22,
                width: 320,
                maxWidth: '85vw',
                height: 400,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'relative',
                color: 'white',
                textDecoration: 'none',
                [theme.breakpoints.up('lg')]: {
                    width: 'calc(25% - 24px)',
                },
                '&::before': {
                    position: 'absolute',
                    top: 15,
                    left: '0',
                    right: '0',
                    bottom: '0',
                    content: '""',
                    display: 'block',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 80%,rgba(0,0,0,1) 100%)',
                    opacity: '0.7',
                },
                '& .tag': {
                    position: 'absolute',
                    top: 20,
                    left: 28,
                    fontSize: 8,
                    letterSpacing: '0.152em',
                    lineHeight: '1.5',
                    fontFamily: theme.subHeaderFont,
                    textTransform: 'uppercase',
                },
                '& .room-title': {
                    fontFamily: theme.headerFont,
                    fontSize: 27,
                    lineHeight: '1.3',
                    position: 'absolute',
                    bottom: 95,
                    left: 28,
                    width: 150,
                    whiteSpace: 'break-spaces',
                },
                '& .cta': {
                    color: 'white',
                    fontSize: 13,
                    lineHeight: '1.3',
                    borderBottom: '2px solid white',
                    position: 'absolute',
                    bottom: 30,
                    left: 28,
                    padding: '3px 0',
                    letterSpacing: '0.03em',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                }
            },
        }
    },
    accommodationOtherRoomsTheme: {
        ...theme.accommodationOtherRoomsTheme,
    }
})