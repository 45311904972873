import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIntroGallerySplitBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`intro-gallery-split ${props.attributes.className ? props.attributes.className : ''} ${classes.introGallerySplit} ${classes.introGallerySplitTheme}`}>
        <Grid container spacing={3} direction="row" justiy="center" alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
                {blocks[1]}
                {blocks[2]}
                {props.attributes.hasButton === true &&
                    blocks[3]
                }
            </Grid>
            <Grid item xs={12} md={8} lg={9} className="image-wrap">
                {blocks[0]}
            </Grid>
        </Grid>
    </div>
};

export const CgbTwoColIntroBlockFragment = graphql`
  fragment CgbIntroGallerySplitBlock on wp_Block {
    ... on wp_CgbIntroGallerySplitBlock {
        name
        attributes {
            className
            hasButton
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIntroGallerySplitBlockPreview = `
    ... on CgbIntroGallerySplitBlock {
        name
        attributes {
            className
            hasButton
        }
    }
`;