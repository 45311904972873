import arrowNext from "../../../images/arrow-next-light.png"
import arrowPrev from "../../../images/arrow-prev-light.png"

export default theme => ({
  testimonialSlider: {
    position: "relative",
    marginBottom: 84,
    "& h1, & h2": {
      marginBottom: 40,
    },
    "& .slick-slider": {
      [theme.breakpoints.up("md")]: {
        marginBottom: 32,
      },
    },
    "& .slick-arrow": {
      top: 0,
      width: "10%",
      height: "auto",
      transform: "none",
      aspectRatio: 0.1,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundColor: "transparent !important",
      backgroundPosition: "center !important",
      zIndex: 10,
      [theme.breakpoints.up("md")]: {
        aspectRatio: "none",
        width: 44,
        height: 44,
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: "50%",
        top: "calc(50% - 22px)",
      },
      "&::before": {
        display: "none",
      },
      "&.slick-prev": {
        left: 0,
        backgroundImage: `url(${arrowPrev})`,
        [theme.breakpoints.up("md")]: {
          left: -12,
        },
        [theme.breakpoints.up(1220)]: {
          left: -66,
        },
      },
      "&.slick-next": {
        right: 0,
        backgroundImage: `url(${arrowNext})`,
        [theme.breakpoints.up("md")]: {
          right: -12,
        },
        [theme.breakpoints.up(1220)]: {
          right: -66,
        },
      },
      "&.slick-disabled": {
        opacity: 0,
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.up("md")]: {
        bottom: -52,
      },
      "& li": {
        "& button": {
          width: 16,
          height: 16,
          margin: 2,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "50%",
          opacity: "0.25",
          transition: "opacity 250ms ease-in-out",
          "&::before": {
            display: "none",
          },
          "&:hover, &:focus": {
            opacity: "0.5",
          },
        },
        "&.slick-active": {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
})
