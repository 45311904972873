export default theme => ({
  introGalleryMixed: {
    position: "relative",
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 32,
      marginTop: 0,
      fontSize: 37,
      fontWeight: "normal",
      textAlign: "center",
      color: theme.palette.wedding.accent,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& p": {
      marginBottom: 32,
      fontSize: 13,
      [theme.breakpoints.up("md")]: {
        width: "66.666%",
        fontSize: 14,
        marginInline: "16.666%",
      },
    },
    "& ul": {
      gap: "26px",
      width: "calc(100% + 34px) !important",
      margin: "0 -17px 32px !important",
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gap: "44px",
        gridTemplateColumns: "calc(65% - 22px) calc(35% - 22px)",
        gridTemplateRows: "1fr 1fr",
      },
      "& li": {
        padding: "0 !important",
        flex: "0 0 calc(50% - 13px)",
        [theme.breakpoints.up("md")]: {
          width: "100% !important",
        },
        "& .gatsby-image-wrapper": {
          [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "100%",
          },
          "& > div": {
            paddingBottom: "0 !important",
            aspectRatio: "0.84",
            [theme.breakpoints.up("md")]: {
              aspectRatio: "auto",
              width: "100%",
              height: "100%",
            },
          },
        },
        "&:nth-of-type(1)": {
          flex: "0 0 100%",
          [theme.breakpoints.up("md")]: {
            gridArea: "1/1/3/2",
            width: "100% !important",
          },
          "& .gatsby-image-wrapper": {
            "& > div": {
              paddingBottom: "0 !important",
              aspectRatio: "1.77",
              [theme.breakpoints.up("md")]: {
                aspectRatio: 1.24,
              },
            },
          },
        },
      },
    },
    "& a": {
      display: "block",
      margin: "0 auto",
      width: "fit-content",
      "& button": {
        minWidth: 174,
        backgroundColor: theme.palette.wedding.button,
        color: "white",
        fontFamily: "Libre Baskerville",
        fontSize: 15,
        fontWeight: "normal",
        padding: 8,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.wedding.button,
          textTransform: "0 0 4px 4px rgba(0,0,0.25)",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 17,
          borderRadius: 6,
          padding: 15,
          minWidth: 244,
        },
      },
    },
  },
})
