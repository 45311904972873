export default theme => ({
    latestPosts: {
        marginBottom: 36,
        // margin: '90px 0 110px',
        [theme.breakpoints.up('md')]: {
            // margin: '90px 0 200px',
            '& .latest-post-grid': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                '& .split': {
                    display: 'block',
                    flex: '0 0 2px',
                    height: 'auto',
                    margin: '0 70px 0 64px',
                }
            }
        },
        '& .split': {
            display: 'block',
            height: 2,
            margin: '24px 0',
            backgroundColor: '#d6d6d6',
        },
        '& .newest': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(50% - 64px)',
            },
            '& a': {
                marginBottom: 10,
                borderRadius: 10,
                display: 'block',
                height: 230,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                fontSize: 20,
                color: 'white',
                lineHeight: '1.15',
                letterSpacing: '-0.008em',
                position: 'relative',
                textDecoration: 'none',
                fontFamily: theme.headerFont,
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                overflow: 'hidden',
                '& .title': {
                    position: 'absolute',
                    zIndex: '1',
                    top: 16,
                    left: 16,
                    right: 16,
                },
                [theme.breakpoints.up('md')]: {
                    height: '100%',
                    fontSize: 28,
                    '& .title': {
                        position: 'absolute',
                        left: 30,
                        top: 'auto',
                        bottom: 70,
                        right: 100,
                    }
                },
                '& .gatsby-image-wrapper': {
                    '& > div': {
                        paddingBottom: '230px !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '100%',
                        '& > div': {
                            height: '100%',
                        },
                    },
                },
                '&::before': {
                    position: 'absolute',
                    zIndex: '1',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)',
                    opacity: '0.6',
                    content: '""',
                    [theme.breakpoints.up('md')]: {
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)',
                        top: 'auto',
                        height: 275,
                    },
                },
                '& .more': {
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    textTransform: 'uppercase',
                    fontSize: 16,
                    letterSpacing: '0.03em',
                    lineHeight: '1.25',
                    borderBottom: '2px solid white',
                    padding: '3px 0',
                    zIndex: '1',
                    [theme.breakpoints.up('md')]: {
                        right: 'auto',
                        left: 30,
                        bottom: 30,
                    },
                }
            }
        },
        '& .more': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(50% - 70px)',
                paddingTop: 10,
            },
            '& .more-link': {
                margin: '24px 0',
                textDecoration: 'none',
                fontSize: 18,
                letterSpacing: '-0.008em',
                fontFamily: theme.headerFont,
                lineHeight: 1.33,
                color: theme.palette.secondary.main,
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    marginRight: 80,
                    fontSize: 24,
                },
            },
            '& hr': {
                border: '1px solid #D6D6D6',
                margin: '0 auto',
            },
            '& a:not([class])': {
                textAlign: 'center',
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    textAlign: 'left',
                },
                '& button': {
                    fontFamily: theme.headerFont,
                    padding: '12px 22px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                    fontSize: 16,
                    lineHeight: 1.2,
                    fontWeight: 400,
                    textAlign: 'center',
                    minWidth: 185,
                    textTransform: 'uppercase',
                    margin: '30px auto 0',
                }
            }
        }
    },
    latestPostsTheme: {
        ...theme.latestPostsTheme,
    }
})