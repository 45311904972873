import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfIconRowBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`icon-row ${useClasses.iconRow}`}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={9}>
          {blocks[0]}
          {blocks[1]}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={4} md={3}>
          {blocks[2]}
        </Grid>
        <Grid item xs={4} md={3}>
          {blocks[3]}
        </Grid>
        <Grid item xs={4} md={3}>
          {blocks[4]}
        </Grid>
      </Grid>
    </div>
  )
}

export const AcfIconRowBlockFragment = graphql`
  fragment AcfIconRowBlock on wp_Block {
    ... on wp_AcfIconRowBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIconRowBlockPreview = `
... on AcfIconRowBlock {
  name
}
`
