import React, { useState } from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Button from "@material-ui/core/Button/Button"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CgbImageGalleryGridInnerLargeBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  const [active, setActive] = useState("")

  return (
    <div
      className={`grid-gallery-inner-large ${props.attributes.display} ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.galleryGridInnerLarge} ${classes.galleryGridInnerLargeTheme}`}
    >
      {blocks[0].props.block.blockBrewGallery.brewGallery.map(
        (image, index) => {
          return (
            <div
              className="image-item"
              onClick={() => setActive(image.id)}
              onKeyDown={() => setActive(image.id)}
              key={image.id}
              role="button"
              tabIndex={index}
            >
              {image.mediaItemUrlSharp && (
                <div className="gatsby-image-wrapper blocks-gallery-item">
                  <div
                    style={{
                      paddingBottom:
                        (image.mediaDetails.height / image.mediaDetails.width) *
                          100 +
                        "%",
                    }}
                  />
                  <img
                    src={image.mediaItemUrlSharp.publicURL}
                    alt={image.altText}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                  />
                </div>
              )}
              {!image.mediaItemUrlSharp && (
                <div className="gatsby-image-wrapper blocks-gallery-item">
                  <img src={image.mediaItemUrl} alt={image.altText} />
                </div>
              )}
            </div>
          )
        }
      )}
      {blocks[0].props.block.blockBrewGallery.brewGallery.map(image => {
        return (
          <div
            className="full-screen-image"
            data-active={image.id === active ? true : false}
            key={"full-" + image.id}
          >
            <Button className="close-image" onClick={() => setActive("")}>
              Close <span></span>
            </Button>
            {image.mediaItemUrlSharp && (
              <div className="gatsby-image-wrapper blocks-gallery-item">
                <div
                  style={{
                    paddingBottom:
                      (image.mediaDetails.height / image.mediaDetails.width) *
                        100 +
                      "%",
                  }}
                />
                <img
                  src={image.mediaItemUrlSharp.publicURL}
                  alt={image.altText}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
              </div>
            )}
            {!image.mediaItemUrlSharp && (
              <div className="gatsby-image-wrapper blocks-gallery-item">
                <img src={image.mediaItemUrl} alt={image.altText} />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export const CgbImageGalleryGridInnerLargeBlockFragment = graphql`
  fragment CgbImageGalleryGridInnerLargeBlock on wp_Block {
    ... on wp_CgbImageGalleryGridInnerLargeBlock {
      name
      attributes {
        className
        display
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageGalleryGridInnerLargeBlockPreview = `
    ... on CgbImageGalleryGridInnerLargeBlock {
        name
        attributes {
            className
            display
        }
    }
`
