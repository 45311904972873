import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

export const GravityformsFormBlock = (props) => {
    return <>
        <iframe title={"gf-" + props.attributes.formId} src={`${process.env.GATSBY_ADMIN_URL}gfembed/?f=${props.attributes.formId}`} width="100%" height="550" frameBorder="0" className="gfiframe"></iframe>
        <script src={`${process.env.GATSBY_ADMIN_URL}wp-content/plugins/brew-gravity-forms-iframe/assets/scripts/gfembed.min.js`} type="text/javascript"></script>
    </>
};

GravityformsFormBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const GravityformsFormBlockFragment = graphql`
  fragment GravityformsFormBlock on wp_Block {
    ... on wp_GravityformsFormBlock {
      name
      attributes {
        __typename
        ...on wp_GravityformsFormBlockAttributes {
          formId
        }
      }
    }
  }`;

  export const GravityformsFormBlockPreview = `
  ... on GravityformsFormBlock {
    name
    attributes {
        __typename
        ...on GravityformsFormBlockAttributes {
          formId
        }
      }
  }
  `;