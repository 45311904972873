import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfIntroGalleryTwoMixedBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div
      className={`intro-gallery-two-mixed ${
        props.block.blockIntroGalleryTwoMixed.imageRight ? `image-right` : ``
      } ${useClasses.introGalleryMixed}`}
    >
      {blocks.map(block => {
        return block
      })}
    </div>
  )
}

export const AcfIntroGalleryTwoMixedBlockFragment = graphql`
  fragment AcfIntroGalleryTwoMixedBlock on wp_Block {
    ... on wp_AcfIntroGalleryTwoMixedBlock {
      name
      blockIntroGalleryTwoMixed {
        imageRight
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIntroGalleryTwoMixedBlockPreview = `
... on AcfIntroGalleryTwoMixedBlock {
    name
    blockIntroGalleryTwoMixed {
      imageRight
    }
}
`
