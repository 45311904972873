import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { graphql } from "gatsby";
import Button from '@material-ui/core/Button';
import Link from '../../Link';
import Grid from "@material-ui/core/Grid/Grid";
import { trackRoomBookingClick } from '../../../services/gtm';
import Img from "gatsby-image";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbAccommodationRoomListBlock = (props) => {

    const classes = useStyles();

    /**
     * The blog posts should be passed through from the parent page directly to this component
     * This will only happen on custom page templates like the blog list page 
     * Preview will not have the posts information for this page due to how the query works, so if this not set
     * use some placeholder data to populate the posts
     * */ 
    let { rooms } = props.parentAttributes;

    // Toggle output based on Attributes
    // props.attributes.display : list / grid

    if( typeof(rooms)==='undefined') {
        // set some sample data
        rooms = [
            {
                "node": {
                    title: "Example Room",                    
                    uri: '/',
                    RoomData: {
                        excerpt: "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
                        listImage: {
                          sourceUrl: ""
                        }
                    }
                }
            }
        ]
    }
    
    return (
        <div className={`CgbAccommodationRoomListBlock ${props.attributes.className ? props.attributes.className : ''} ${classes.accommodationListing} ${classes.accommodationListingTheme}`}>
            {rooms.map(room => {
                let image = null;
                if(room.node.RoomData.listImage.mediaItemUrlSharp){
                    if(room.node.RoomData.listImage.mediaItemUrlSharp.childImageSharp){
                        image = <Img
                            fluid={room.node.RoomData.listImage.mediaItemUrlSharp.childImageSharp.fluid}
                            alt={room.node.RoomData.listImage.alt ? room.node.RoomData.listImage.alt : ''}
                        />
                    } else {
                        // Gifs do not have a childImageSharp
                        image = <img
                            src={room.node.RoomData.listImage.mediaItemUrlSharp.childImageSharp.publicURL}
                            alt={props.attributes.alt ? props.attributes.alt : ''}
                        />
                    }
                } else {
                    // console.log(props.attributes.height);
                    // This is a preview image
                    image = <div className="gatsby-image-wrapper">
                        <div style={{ paddingBottom: '100%' }} />
                        <img
                            src={room.node.RoomData.listImage.mediaItemUrl}
                            alt={room.node.RoomData.listImage.alt ? room.node.RoomData.listImage.alt : ''}
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                        />
                    </div>
                }
                return <Grid container spacing={3} direction="row" alignItems="center" key={room.node.uri}>
                    <Grid item xs={12} md={7} lg={8} >
                        {image}
                    </Grid>
                    <Grid item xs={12} md={5} lg={4} >
                        <h2>{room.node.title}</h2>
                        <p>{room.node.RoomData.excerpt.replace(/(<([^>]+)>)/gi, "")}</p>
                        <p className="rates">Best rates guaranteed from <span className="price">£{room.node.RoomData.price}</span></p>
                        <div className="actions">
                            <Link to={room.node.uri} className="cta">
                                {props.attributes.btnText}
                            </Link>

                            <Link to={props.pageContext.bookingLink} style={{ textDecoration: 'none' }} onClick={() => trackRoomBookingClick()}>
                                <Button
                                    variant="contained"
                                    title={props.attributes.btnText}
                                    color={'primary'}
                                >
                                    Book Room
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            })}
        </div>
    )
};

CgbAccommodationRoomListBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbAccommodationRoomListBlockFragment = graphql`
  fragment CgbAccommodationRoomListBlock on wp_Block {
    ... on wp_CgbAccommodationRoomListBlock {
      name
      attributes {
        __typename
        ... on wp_CgbAccommodationRoomListBlockAttributes {
            className
            display
            btnText
        }
      }
    }
  }`;

export const CgbAccommodationRoomListBlockPreview = `
... on CgbAccommodationRoomListBlock {
  name
  attributes {
    __typename
    ... on CgbAccommodationRoomListBlockAttributes {
      className
      display
      btnText
    }
  }
}
`;