export default theme => ({
    blogPostWrapper: {
        position: 'relative',
        marginBottom: 36,
        padding: '50px 0',
        '& .MuiGrid-item': {
            '& > span.image-with-caption, & > .gatsby-image-wrapper, & > ul.MuiGridList-root' : {
                width: 'calc(100% + 36px)',
                margin: '0 -18px',
                marginBottom: 36,
                [theme.breakpoints.up('md')]: {
                    width: '110%',
                    margin: '0 0 36px -10%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: 'calc((100% / 8) * 9)',
                    margin: '0 0 36px calc( 0px - (100% / 8))',
                }
            },
            '& > ul.MuiGridList-root' : {
                width: 'calc(100% + 52px) !important',
                margin: '0 -26px 36px !important',
                [theme.breakpoints.up('md')]: {
                    width: 'calc(110% + 16px) !important',
                    margin: '0 0 36px  calc(-8px - 10%) !important',
                },
                [theme.breakpoints.up('lg')]: {
                    width: 'calc(((100% / 8) * 9) + 16px) !important',
                    margin: '0 0 36px  calc( -8px - (100% / 8)) !important',
                }
            },
        },
        '& span.image-with-caption': {
            display: 'block',
        }
    },
})