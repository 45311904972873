import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Link from '../../Link';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbCardBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`card ${props.attributes.className ? props.attributes.className : ''} ${classes.cardBlock} ${classes.cardBlockTheme}`}>
        {blocks[0]}
        <div className="con-wrap">
            <div className="min-height">
                {blocks[1]}
                {blocks[2]}
            </div>
            <div className="links">
                {blocks[3]}
                {props.attributes.cardLink !== '' && props.attributes.cardLink !== '##' &&
                    <Link to={props.attributes.cardLink} className="cta">{props.attributes.ctaText}</Link>
                }
            </div>
        </div>
    </div>
};

export const CgbCardBlockFragment = graphql`
  fragment CgbCardBlock on wp_Block {
    ... on wp_CgbCardBlock {
        name
        attributes {
            cardLink
            ctaText
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCardBlockPreview = `
    ... on CgbCardBlock {
        name
        attributes {
            cardLink
            ctaText
            className
        }
    }
`;