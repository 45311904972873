import React from "react"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { transformLink } from "../../../helpers"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"

const useStyles = wedding =>
  makeStyles(theme => ({
    header: {
      display: "block",
      position: "relative",
      marginBottom: 12,
      marginTop: 24,
      fontFamily: wedding ? "Birthstone Bounce" : theme.headerFont,
    },
  }))

export const CoreHeadingBlock = props => {
  const classes = useStyles(props.pageContext.wedding)()

  return (
    <React.Fragment>
      {props.attributes.anchor !== "" && (
        <div id={`${props.attributes.anchor}`}></div>
      )}
      <Typography
        component={
          props.attributes.level !== null ? `h${props.attributes.level}` : "h2"
        }
        variant={
          props.attributes.level !== null ? `h${props.attributes.level}` : "h2"
        }
        color={
          props.attributes.textColor !== null
            ? props.attributes.textColor
            : "initial"
        }
        align={
          props.attributes.textAlign !== null
            ? props.attributes.textAlign
            : "left"
        }
        className={` ${props.attributes.className} ${classes.header}`}
      >
        {ReactHtmlParser(props.attributes.content, {
          transform: transformLink,
        })}
      </Typography>
    </React.Fragment>
  )
}

CoreHeadingBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const CoreHeadingBlockFragment = graphql`
  fragment CoreHeadingBlock on wp_Block {
    ... on wp_CoreHeadingBlock {
      name
      attributes {
        __typename
        ... on wp_CoreHeadingBlockAttributes {
          level
          anchor
          textColor
          className
          align
          textAlign
          content
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CoreHeadingBlockPreview = `
... on CoreHeadingBlock {
  name
  attributes {
    __typename
    ... on CoreHeadingBlockAttributes {
      level
      anchor
      textColor
      className
      align
      textAlign
      content
    }
  }
}
`
