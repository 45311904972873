import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import Img from "gatsby-image";
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CgbImageSliderBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  // console.log(blocks);

  const settings = {
    dots: true,
    infinite: true,
    // centerMode: true,
    // lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }

  return (
    <div
      className={`image-slider ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.imageSlider} ${classes.imageSliderTheme}`}
    >
      <Slider {...settings}>
        {blocks[0].props.block.blockBrewGallery.brewGallery.map(image => {
          return (
            <div className="image-slide" key={image.id}>
              {image.mediaItemUrlSharp && (
                <div className="gatsby-image-wrapper blocks-gallery-item">
                  <div
                    style={{
                      paddingBottom:
                        (image.mediaDetails.height / image.mediaDetails.width) *
                          100 +
                        "%",
                    }}
                  />
                  <img
                    src={image.mediaItemUrlSharp.publicURL}
                    alt={image.altText}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                  />
                </div>
              )}
              {!image.mediaItemUrlSharp && (
                <div className="gatsby-image-wrapper blocks-gallery-item">
                  <img src={image.mediaItemUrl} alt={image.altText} />
                </div>
              )}
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export const CgbImageSliderBlockFragment = graphql`
  fragment CgbImageSliderBlock on wp_Block {
    ... on wp_CgbImageSliderBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageSliderBlockPreview = `
    ... on CgbImageSliderBlock {
        name
        attributes {
            className
        }
    }
`
