import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfIconRowInnerBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  return (
    <div className={`icon-row-inner ${classes.iconRowInner}`}>
      {blocks[0]}
      {blocks[1]}
    </div>
  )
}

export const AcfIconRowInnerBlockFragment = graphql`
  fragment AcfIconRowInnerBlock on wp_Block {
    ... on wp_AcfIconRowInnerBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIconRowInnerBlockPreview = `
... on AcfIconRowInnerBlock {
  name
}
`
