import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
const useStyles = makeStyles(theme => styles(theme))

export const AcfIframeEmbedBlock = props => {
  const classes = useStyles()

  return (
    <div className={`iframe-embed ${classes.iframeEmbed}`}>
      <iframe
        src={props.block.blockIframeEmbed.formUrl}
        width="100%"
        height={props.block.blockIframeEmbed.frameHeight}
      />
    </div>
  )
}

export const AcfIframeEmbedBlockFragment = graphql`
  fragment AcfIframeEmbedBlock on wp_Block {
    ... on wp_AcfIframeEmbedBlock {
      name
      blockIframeEmbed {
        formUrl
        frameHeight
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIframeEmbedBlockPreview = `
    ... on AcfIframeEmbedBlock {
        name
        blockIframeEmbed {
          formUrl
          frameHeight
        }
    }
`
