export default theme => ({
    fileListInner: {
        position: 'relative',
        backgroundColor: 'white',
        boxShadow: '0 7px 6px rgba(0,0,0,0.16)',
        margin: '0 auto 12px',
        maxWidth: 1095,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 32,
            alignItems: 'center',
        },
        '& .con-col': {
            padding: '32px 24px',
            [theme.breakpoints.up('md')]: {
                padding: 50,
            }
        },
        '& .image-col': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
            }
        }
    },
})