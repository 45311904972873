export default theme => ({
  testimonialSliderInner: {
    position: "relative",
    "& .MuiGrid-grid-lg-1": {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
    "& .gatsby-image-wrapper": {
      margin: 12,
      "& > div": {
        aspectRatio: 1,
        paddingBottom: "0px !important",
        [theme.breakpoints.up("md")]: {
          aspectRatio: 1.33,
        },
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginTop: 0,
      marginBottom: 20,
      [theme.breakpoints.up("md")]: {
        marginBottom: 32,
      },
    },
    "& a": {
      width: "fit-content",
      display: "block",
      margin: "0 auto 32px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "0 !important",
      },
    },
  },
})
