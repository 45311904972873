import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import Button from '@material-ui/core/Button';
import Link from '../../Link';

const useStyles = makeStyles((theme) => ({
    root: {
        '& button': {
            fontFamily: theme.headerFont,
            padding: '12px 22px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 400,
            textAlign: 'center',
            minWidth: 185,
            textTransform: 'uppercase',
        }
    },
}));

export const CoreButtonBlock = (props) => {
    const classes = useStyles();
  
    let variant = 'contained';

    let attributes = props.attributes;

    if(typeof(props.attributes.text) === 'undefined') {
      attributes = JSON.parse(props.attributesJSON);
    }

    if(typeof(attributes.className) !== 'undefined' && attributes.className !== null) {
        if (attributes.className.indexOf('is-style-outline') !== -1) variant = 'outlined';
    }

    return <Link to={attributes.url} style={{ textDecoration: 'none' }} className={classes.root}><Button
      variant={variant}
      title={attributes.title}
      style={{ margin: 0 }}
      color={attributes.backgroundColor ? attributes.backgroundColor : 'primary'}
      className={`${attributes.className} ${attributes.textColor}`}
    >{ReactHtmlParser(attributes.text)}</Button></Link>
};

CoreButtonBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreButtonBlockFragment = graphql`
  fragment CoreButtonBlock on wp_Block {
    ... on wp_CoreButtonBlock {
      name
      attributesJSON
      attributes {
        __typename
       ... on wp_CoreButtonBlockAttributes {
          backgroundColor
          className
          gradient
          linkTarget
          placeholder
          rel
          text
          textColor
          title
          url
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreButtonBlockPreview = `
... on CoreButtonBlock {
  name
  attributesJSON
  attributes {
    __typename
   ... on CoreButtonBlockAttributes {
      backgroundColor
      className
      gradient
      linkTarget
      placeholder
      rel
      text
      textColor
      title
      url
    }
  }
}
`;