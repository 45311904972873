import React from "react"
import { graphql } from "gatsby"
import { buildBlocks } from "../../../services/builder"

export const CoreBlock = props => {
  const blocks = buildBlocks(props.innerBlocks, [], props.pageContext)
  return (
    <>
      {blocks.map(block => {
        return block
      })}
    </>
  )
}

export const CoreBlockFragment = graphql`
  fragment CoreBlock on wp_Block {
    ... on wp_CoreBlock {
      reusableBlock {
        __typename
        ... on wp_ReusableBlock {
          id
          blocks {
            ...CoreFields
          }
        }
      }
    }
  }
`

// This is queried direct in the preview page query
export const CoreBlockPreview = `
... on CoreBlock {
  reusableBlock {
    __typename
    ... on ReusableBlock {
      id
    }
  }
}
`
