import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTwoCardIntroBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`two-card-intro ${props.attributes.className ? props.attributes.className : ''} ${classes.twoCardIntro} ${classes.twoCardIntroTheme}`}>
        <div className="con-col">
            <span className="tag">{props.attributes.tag}</span>
            {blocks[0]}
            {blocks[1]}
        </div>
        <div className="cards">
            {blocks[2]}
            {blocks[3]}
        </div>
    </div>
};

export const CgbTwoCardIntroBlockFragment = graphql`
  fragment CgbTwoCardIntroBlock on wp_Block {
    ... on wp_CgbTwoCardIntroBlock {
        name
        attributes {
            tag
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTwoCardIntroBlockPreview = `
    ... on CgbTwoCardIntroBlock {
        name
        attributes {
            tag
            className
        }
    }
`;