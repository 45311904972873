export default theme => ({
  gridSliderGallery: {
    position: "relative",
    marginBottom: 40,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 40,
      marginTop: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& a": {
      width: "fit-content",
      display: "block",
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        marginBottom: "0 !important",
      },
      "& button": {
        minWidth: 174,
        backgroundColor: theme.palette.wedding.button,
        color: "white",
        fontFamily: "Libre Baskerville",
        fontSize: 15,
        fontWeight: "normal",
        padding: 8,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.wedding.button,
          textTransform: "0 0 4px 4px rgba(0,0,0.25)",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 17,
          borderRadius: 6,
          padding: 15,
          minWidth: 244,
        },
      },
    },
    "& .gallery-slide": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      gap: "15px",
      [theme.breakpoints.up("md")]: {
        gap: "35px",
      },
      "& button": {
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        fontSize: 0,
      },
      "& img": {
        display: "block",
        aspectRatio: 1,
        objectFit: "cover",
        objectPosition: "center",
        width: "100%",
      },
    },
    "& .slick-slider": {
      marginBottom: 90,
      width: "calc(100% + 38px)",
      marginInline: "-18px",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        marginInline: "auto",
      },
    },
    "& .slick-arrow": {
      top: 0,
      width: "10%",
      height: "auto",
      aspectRatio: 0.1,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundColor: "transparent !important",
      backgroundPosition: "center !important",
      zIndex: 10,
      transform: "none",
      [theme.breakpoints.up("md")]: {
        width: 44,
        height: 44,
        aspectRatio: "none",
        top: "calc(50% - 22px)",
      },
      "&::before": {
        display: "block",
        position: "absolute",
        width: 44,
        aspectRatio: 1,
        backgroundColor: theme.palette.wedding.accent,
        content: "''",
        borderRadius: "50%",
        top: "calc(50% - 22px)",
        opacity: 1,
      },
      "&::after": {
        width: 16,
        aspectRatio: 1,
        transformOrigin: "50% 50%",
        borderTop: `3px solid #858484`,
        borderRight: `3px solid #858484`,
        borderRadius: 3,
        content: "''",
        display: "block",
        position: "absolute",
        top: "calc(50% - 8px)",
      },
      "&.slick-prev": {
        left: 32,
        [theme.breakpoints.up("md")]: {
          left: -46,
        },
        "&::before": {
          left: -22,
        },
        "&::after": {
          transform: "rotate(-135deg)",
          left: -5,
        },
      },
      "&.slick-next": {
        right: 32,
        [theme.breakpoints.up("md")]: {
          right: -46,
        },
        "&::before": {
          right: -22,
        },
        "&::after": {
          transform: "rotate(45deg)",
          right: -5,
        },
      },
      "&.slick-disabled": {
        opacity: 0,
      },
    },
    "& .slick-dots": {
      bottom: -52,
      "& li": {
        "& button": {
          width: 12,
          height: 12,
          margin: 2,
          backgroundColor: theme.palette.wedding.button,
          borderRadius: "50%",
          opacity: "0.25",
          transition: "opacity 250ms ease-in-out",
          cursor: "pointer",
          "&::before": {
            display: "none",
          },
          "&:hover, &:focus": {
            opacity: "0.5",
          },
        },
        "&.slick-active": {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
  imagePopUp: {
    position: "fixed",
    top: "5vh",
    height: "90vh",
    left: "50%",
    width: "90vw",
    transform: "translateX(-50%)",
    zIndex: 999,
    maxWidth: 1130,
    padding: 17,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      position: "relative",
      zIndex: 10,
      aspectRatio: 1,
      border: "8px solid " + theme.palette.wedding.accent,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        border: "24px solid " + theme.palette.wedding.accent,
      },
      [`${theme.breakpoints.up("sm")} and (orientation: landscape)`]: {
        width: "auto",
        height: "100%",
      },
      "& img": {
        objectFit: "cover",
        objectPosition: "center",
        width: "100%",
        height: "100%",
        display: "block",
      },
      "& .caption": {
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: 0,
        overflow: "hidden",
        display: "block",
        width: "100%",
        paddingTop: 20,
        "& p": {
          padding: "0 12px 6px 12px",
          left: 0,
          color: "white",
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          boxShadow: "0 0 12px 12px rgba(0,0,0,0.5)",
          textShadow: "0 0 2px black",
          fontSize: 12,
          letterSpacing: "0.032em",
          textAlign: "right",
        },
      },
    },
    "& button.close": {
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "100vw",
      height: "100vh",
      transform: "translate(-50%, -50%)",
      border: "none",
      background: "rgba(0,0,0,0.45)",
      fontSize: 0,
      zIndex: 5,
      cursor: "pointer",
    },
    "& button.close-x": {
      position: "absolute",
      top: "12px",
      right: "12px",
      width: "44px",
      height: "44px",
      border: "none",
      background: "transparent",
      fontSize: 0,
      zIndex: 20,
      cursor: "pointer",
      filter: "drop-shadow(0 0 5px black)",
      "&::before, &::after": {
        content: '""',
        display: "block",
        width: 4,
        height: 30,
        transformOrigin: "50% 50%",
        position: "absolute",
        backgroundColor: "white",
        top: 7,
        right: 20,
      },
      "&::before": {
        transform: "rotate(-45deg)",
      },
      "&::after": {
        transform: "rotate(45deg)",
      },
    },
    "& button.next, & button.prev": {
      backgroundSize: "24px",
      backgroundRepeat: "no-repeat",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      zIndex: 30,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      border: "none",
      fontSize: 0,
      cursor: "pointer",
      aspectRatio: "none",
      width: 44,
      height: 44,
      backgroundColor: `${theme.palette.wedding.accent} !important`,
      borderRadius: "50%",
      top: "calc(50% - 22px)",
      "&::after": {
        width: 16,
        aspectRatio: 1,
        transformOrigin: "50% 50%",
        borderTop: `3px solid #858484`,
        borderRight: `3px solid #858484`,
        borderRadius: 3,
        content: "''",
        display: "block",
        position: "absolute",
        top: "calc(50% - 8px)",
      },
    },
    "& button.prev": {
      left: 12,
      [theme.breakpoints.up("md")]: {
        left: -90,
      },
      "&::after": {
        transform: "rotate(-135deg)",
        marginLeft: 10,
      },
    },
    "& button.next": {
      right: 12,
      [theme.breakpoints.up("md")]: {
        right: -90,
      },
      "&::after": {
        transform: "rotate(45deg)",
        marginLeft: 4,
      },
    },
  },
})
