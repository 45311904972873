import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button/Button"
import Link from "../../Link"
import axios from "axios"
// import ReactRecaptcha from "react-recaptcha";
import WarningIcon from "@material-ui/icons/Warning"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { trackWeddingContactForm } from "../../../services/gtm"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfContactFormWeddingBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  const data = useStaticQuery(graphql`
    query houseInfoWedding {
      wp {
        locationData {
          LocationData {
            crmId
            name
          }
        }
      }
    }
  `)
  var crmID = data.wp.locationData.LocationData.crmId
  var houseName = data.wp.locationData.LocationData.name

  const [formError, setFormError] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSending, setFormSending] = useState(false)

  const [enquiry, setEnquiry] = useState("Wedding")
  const [forename, setForename] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [message, setMessage] = useState("")
  const [recaptchaResponse, setRecaptchaResponse] = useState("") // eslint-disable-line
  //   const emptyValidationErrors = {
  //     forename: [],
  //     surname: [],
  //     email: [],
  //     telephone: [],
  //     message: [],
  //     recaptcha: [],
  //   }
  const [validationErrors, setValidationErrors] = useState([])

  const submitForm = event => {
    // send
    setFormSending(true)
    let postData = {
      type: enquiry,
      forename: forename,
      surname: surname,
      email: email,
      telephone: telephone,
      message: message,
      house_id: crmID,
      house_name: crmID === null ? "" : houseName,
      "g-recaptcha-response": recaptchaResponse,
    }

    axios({
      method: "post",
      url: `${process.env.GATSBY_FORM_ENDPOINTS_URL}${process.env.GATSBY_FORM_ENDPOINTS_WEBSITE_NAME}/wedding-contact-us`,
      data: postData,
    })
      .then(response => {
        console.log("Endpoint response", response)
        if (Boolean(response.data.success)) {
          trackWeddingContactForm()
          setFormSubmitted(true)
        }
        setFormSending(false)
      })
      .catch(error => {
        console.log("Endpoint error", error)
        let errorMessage =
          "There was a problem submitting your message. Please try again later."
        if (error.hasOwnProperty("response")) {
          if (error.response) {
            console.log("Response", error.response)
            if (error.response.data.hasOwnProperty("message")) {
              errorMessage = error.response.data.message
              setValidationErrors([])
            } else if (error.response.data.hasOwnProperty("validation")) {
              setValidationErrors(error.response.data.validation)
              errorMessage = "Please check the form for errors."
            }
          } else if (error.request) {
            console.log("Request", error.request)
          } else {
            console.log("Setup", error.message)
          }
        }
        setFormError(errorMessage)
        setFormSending(false)
      })
  }

  //   const recaptchaVerified = response => {
  //     console.log("Recaptcha response", response)
  //     setRecaptchaResponse(response)
  //   }

  //   console.log(validationErrors)

  return (
    <div
      className={`contact-form ${
        props.attributes.className ? props.attributes.className : ""
      } ${useClasses.contactForm} ${useClasses.contactFormTheme}`}
    >
      {blocks.map(block => {
        return block
      })}

      {formSubmitted && formError.length === 0 && (
        <div className={useClasses.thanks}>
          <p>
            <CheckCircleIcon /> Thank you for your enquiry!
          </p>

          <p>
            We're delighted that you're considering {houseName} for your
            {enquiry === "Wedding"
              ? " special day. Your details have been sent to our wedding team who will be in touch shortly."
              : " event. Your details have been sent to the team who will be in touch shortly."}
          </p>
        </div>
      )}

      {!formSubmitted && formError.length !== 0 && (
        <p className={useClasses.errors}>
          <WarningIcon /> {formError}
        </p>
      )}
      {!formSubmitted && (
        <form>
          <Grid container spacing={5} direction="row" justify="center">
            <Grid item xs={12} md={5} style={{ padding: "0 40px" }}>
              <label htmlFor="e-type" className={useClasses.label}>
                I have an enquiry about
              </label>
              <select
                id="e-type"
                className={`${useClasses.select}`}
                onChange={e => {
                  console.log("type selected", e.target.value)
                  setEnquiry(String(e.target.value))
                }}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.type)}
              >
                <option value="Wedding">Wedding</option>
                <option value="Event">Event</option>
              </select>
              {validationErrors.type && (
                <p className={useClasses.validationError}>
                  {validationErrors.type}
                </p>
              )}
              <label htmlFor="f-name" className={useClasses.label}>
                First Name
              </label>
              <TextField
                id="f-name"
                variant="outlined"
                className={`${useClasses.inputs} ${
                  validationErrors.forename ? "has-error" : ""
                }`}
                placeholder="Frederic"
                onChange={e => setForename(String(e.target.value))}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.forename)}
              />
              {validationErrors.forename && (
                <p className={useClasses.validationError}>
                  {validationErrors.forename}
                </p>
              )}
              <label htmlFor="s-name" className={useClasses.label}>
                Last Name
              </label>
              <TextField
                id="s-name"
                variant="outlined"
                className={`${useClasses.inputs} ${
                  validationErrors.surname ? "has-error" : ""
                }`}
                placeholder="Robinson"
                onChange={e => setSurname(String(e.target.value))}
              />
              {validationErrors.surname && (
                <p className={useClasses.validationError}>
                  {validationErrors.surname}
                </p>
              )}
              <label htmlFor="email" className={useClasses.label}>
                Email Address
              </label>
              <TextField
                id="email"
                variant="outlined"
                className={`${useClasses.inputs} ${
                  validationErrors.email ? "has-error" : ""
                }`}
                placeholder="Frederic.Robinson@email.com"
                onChange={e => setEmail(String(e.target.value))}
              />
              {validationErrors.email && (
                <p className={useClasses.validationError}>
                  {validationErrors.email}
                </p>
              )}
              <label htmlFor="tel" className={useClasses.label}>
                Phone Number
              </label>
              <TextField
                id="tel"
                variant="outlined"
                className={`${useClasses.inputs} ${
                  validationErrors.telephone ? "has-error" : ""
                }`}
                placeholder="07788 255 369"
                onChange={e => setTelephone(String(e.target.value))}
              />
              {validationErrors.telephone && (
                <p className={useClasses.validationError}>
                  {validationErrors.telephone}
                </p>
              )}
            </Grid>
            <Grid item xs={12} md={5} style={{ padding: "0 40px" }}>
              <label htmlFor="message" className={useClasses.label}>
                Message
              </label>
              <TextField
                id="message"
                variant="outlined"
                className={`${useClasses.inputs} ${
                  validationErrors.message ? "has-error" : ""
                }`}
                placeholder="Message"
                onChange={e => setMessage(String(e.target.value))}
                multiline
              />
              {validationErrors.message && (
                <p className={useClasses.validationError}>
                  {validationErrors.message}
                </p>
              )}
              {/*<ReactRecaptcha className={useClasses.recaptcha}
                                    sitekey={process.env.GATSBY_GRECAPTCHA_KEY}
                                    render="explicit"
                                    verifyCallback={recaptchaVerified}
                    />*/}
              <p className={useClasses.privacy}>
                See our <Link to="/privacy-policy">Privacy Policy</Link> for
                full details of how we process your personal data
              </p>
              <Button
                variant="contained"
                color="primary"
                className={useClasses.button}
                disabled={formSending}
                onClick={event => submitForm(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  )
}

export const AcfContactFormWeddingBlockFragment = graphql`
  fragment AcfContactFormWeddingBlock on wp_Block {
    ... on wp_AcfContactFormWeddingBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfContactFormWeddingBlockPreview = `
  ... on AcfContactFormWeddingBlock {
    name
    attributes {
      className
    }
  }
`
