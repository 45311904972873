import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => styles(theme))

export const AcfTenKitesMenusBlock = props => {
  const classes = useStyles()

  function embedTenKites() {
    window.$k10(function() {
      window.$k10.ajax({
        url: props.block.blockTenKitesMenus.url,
        type: "GET",
        crossDomain: true,
        success: function(responseData, textStatus, jqXHR) {
          var $k10tkHtml = window.$k10("<div/>", {
            html: responseData,
          })

          var headContent = $k10tkHtml.find(".tk-html-header-scripts").html()
          var bodyContent = $k10tkHtml.find(".tk-html-body").html()
          var bodyScripts = $k10tkHtml.find(".tk-html-body-scripts").html()
          window.$k10("head").append(headContent)
          window.$k10(".ten-kites-menus-embed").append(bodyContent)
          setTimeout(function() {
            window.$k10("body").append(bodyScripts)
          }, 50)
        },
        error: function(responseData, textStatus, errorThrown) {
          console.log("POST failed")
        },
      })
    })
  }

  if (typeof window !== "undefined") {
    let checkK10 = setInterval(() => {
      if (typeof window.$k10 !== "undefined") {
        clearInterval(checkK10)
        embedTenKites()
      }
    }, 250)
  }

  return (
    <>
      <div className={`ten-kites-menus-embed ${classes.tenKitesMenusEmbed}`}>
        {" "}
      </div>
      <Helmet>
        <script src="https://menus.tenkites.com/scripts/_k10.min.js?sbk=20160720"></script>
      </Helmet>
    </>
  )
}

export const AcfTenKitesMenusBlockFragment = graphql`
  fragment AcfTenKitesMenusBlock on wp_Block {
    ... on wp_AcfTenKitesMenusBlock {
      name
      blockTenKitesMenus {
        url
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTenKitesMenusBlockPreview = `
... on AcfTenKitesMenusBlock {
  name
  blockTenKitesMenus {
    url
  }
}
`
