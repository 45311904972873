import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { Link as GatsbyLink } from "gatsby"
import { trackOutboundLink } from "../../../services/gtm"
import { renderToString } from "react-dom/server"

// mapboxgl.accessToken = 'pk.eyJ1Ijoicm9iaW5zb25zYnJld2VyeSIsImEiOiJja3Jvdzc3NmkxYjVmMndubzkwcWhmemgzIn0.wU2sk1i-nqgDXLhp3Fk0Yw';
mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_KEY

function Map(props) {
  const [pubs, setPubs] = useState(props.pubs)
  const [firstPub, setFirstPub] = useState(props.firstPub)
  const [map, setMap] = useState(false)
  const mapContainer = useRef(null)

  useEffect(() => {
    if (map) {
      setFirstPub(props.firstPub)
      map.setCenter([props.firstPub.longitude, props.firstPub.latitude])
    }
  }, [props])

  useEffect(() => {
    const initMap = ({ setMap, mapContainer }) => {
      let mapFeatures = []

      pubs.map(pub => {
        const pubFound = props.wpData.find(d => d.PubData.crmId === pub.id)
        if (pubFound) {
          const { hasOwnWebsite, url } = pubFound.PubData

          let link = false
          if (hasOwnWebsite) {
            link = (
              <a
                href={url}
                target="_blank"
                onClick={() => trackOutboundLink(url)}
              >
                {" "}
                View Pub{" "}
              </a>
            )
          } else {
            link = (
              <GatsbyLink to={url} onClick={() => trackOutboundLink(url)}>
                {" "}
                View Pub{" "}
              </GatsbyLink>
            )
          }
          link = renderToString(link)

          let town = ""

          if (pub.address2 !== null) {
            town = "<p class='town'>" + pub.address2 + "</p>"
          }
          let phone = ""

          if (pub.telephone !== null) {
            phone =
              '<p class="phone"><a href="tel:' +
              pub.telephone +
              '">' +
              pub.telephone +
              "</a></p>"
          }

          mapFeatures.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [pub.longitude, pub.latitude],
            },
            properties: {
              name: pub.name,
              town: town,
              phone: phone,
              directionsUrl: 'https://www.google.com/maps/dir/?api=1&destination=' + (pub.googlePlaceId ? `${encodeURI(pub.name)}&destination_place_id=${pub.googlePlaceId}` : `${pub.latitude},${pub.longitude}`),
              link: link,
              featuredImage: pub.url_featured_image,
            },
          })
        }
      })

      const map = new mapboxgl.Map({
        // eslint-disable-line
        container: mapContainer.current,
        style: "mapbox://styles/brew-admin/clk0vqq2i001x01pgdhibawkg",
        center: [firstPub.longitude, firstPub.latitude],
        zoom: 10,
      })

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl())

      map.on("load", function() {
        map.loadImage(
          "https://rob-cdn.ams3.digitaloceanspaces.com/website/general/map-marker.png",
          function(error, image) {
            if (error) throw error
            map.addImage("marker", image)
            map.addSource("points", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: mapFeatures,
              },
            })
            map.addLayer({
              id: "points",
              type: "symbol",
              source: "points",
              layout: {
                "icon-image": "marker",
                "icon-size": 1,
              },
            })
            map.on("click", "points", e => {
              const coordinates = e.features[0].geometry.coordinates.slice()

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
              }

              new mapboxgl.Popup({ offset: 32 })
                .setLngLat(coordinates)
                .setHTML(
                  '<img src="' +
                    e.features[0].properties.featuredImage +
                    '" alt="' +
                    e.features[0].properties.name +
                    '" width="280" />' +
                    "<h3>" +
                    e.features[0].properties.name +
                    "</h3>" +
                    e.features[0].properties.town +
                    e.features[0].properties.phone +
                    '<a target="_blank" href="' +
                    e.features[0].properties.directionsUrl +
                    '">Get Directions</a>' +
                    e.features[0].properties.link
                )
                .addTo(map)
            })

            map.on("mouseenter", "points", () => {
              map.getCanvas().style.cursor = "pointer"
            })

            // Change it back to a pointer when it leaves.
            map.on("mouseleave", "points", () => {
              map.getCanvas().style.cursor = ""
            })
          }
        )
        setMap(map)
      })
    }

    if (!map) {
      initMap({ setMap, mapContainer })
    }
  }, [map])

  return (
    <div ref={mapContainer} className={`map-container ${props.className}`} />
  )
}

export default Map
