import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfIntroGallerySquareBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`intro-gallery-square ${useClasses.introGallerySquare}`}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {blocks[0]}
          {blocks[1]}
          {blocks[2]}
          {blocks[3]}
        </Grid>
      </Grid>
    </div>
  )
}

export const AcfIntroGallerySquareBlockFragment = graphql`
  fragment AcfIntroGallerySquareBlock on wp_Block {
    ... on wp_AcfIntroGallerySquareBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIntroGallerySquareBlockPreview = `
... on AcfIntroGallerySquareBlock {
    name
}
`
