export default theme => ({
  twoColumnStacked: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "calc(50% - 12px) calc(50% - 12px)",
      gridTemplateRows: "repeat(5, auto)",
      gridColumnGap: "24px",
      marginBottom: 40,
    },
    "& h1, h2": {
      marginBottom: 24,
      [theme.breakpoints.up("md")]: {
        gridArea: "1 / 1 / 2 / 3",
        marginBottom: 40,
      },
    },
    "& .gatsby-image-wrapper": {
      width: "calc(100% + 36px)",
      marginLeft: -18,
      [theme.breakpoints.up("md")]: {
        width: "100%",
        marginLeft: 0,
        "&:nth-of-type(1)": {
          gridArea: "2 / 1 / 3 / 2",
        },
      },
    },
    "& h3": {
      marginBottom: 16,
      [theme.breakpoints.up("md")]: {
        "&:nth-of-type(1)": {
          gridArea: "3 / 1 / 4 / 2",
        },
      },
    },
    "& p": {
      [theme.breakpoints.up("md")]: {
        "&:nth-of-type(1)": {
          gridArea: "4 / 1 / 5 / 2",
        },
      },
    },
    "& > a": {
      margin: "0 auto 36px",
      display: "block",
      width: "fit-content",
      [theme.breakpoints.up("md")]: {
        "&:nth-of-type(1)": {
          gridArea: "5 / 1 / 6 / 2",
        },
      },
    },
  },
})
