export default theme => ({
    videoEmbed: {
        position: 'relative',
        width: 'calc(100% + 34.8px)',
        margin: '0 -17.4px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0 0 36px',
            width: '100%',
        },
        '& .video-wrapper': {
            paddingBottom: '56% !important',
            width: '100%',
            '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100% !important',
                height: '100% !important',
            },
        },
    },
    videoEmbedTheme: {
        ...theme.videoEmbedTheme,
    }
})