import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfVideoIntroBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`video-intro ${useClasses.videoIntro}`}>
      {blocks[0]}
      {blocks[1]}
    </div>
  )
}

export const AcfVideoIntroBlockFragment = graphql`
  fragment AcfVideoIntroBlock on wp_Block {
    ... on wp_AcfVideoIntroBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfVideoIntroBlockPreview = `
    ... on AcfVideoIntroBlock {
        name
    }
`
