export default theme => ({
  testimonialSlider: {
    position: "relative",
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 40,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& .slick-slider": {
      [theme.breakpoints.up("md")]: {
        marginBottom: 32,
      },
    },
    "& .slick-arrow": {
      top: 0,
      width: "10%",
      height: "auto",
      aspectRatio: 0.1,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundColor: "transparent !important",
      backgroundPosition: "center !important",
      zIndex: 10,
      transform: "none",
      [theme.breakpoints.up("md")]: {
        width: 44,
        height: 44,
        aspectRatio: "none",
        top: "calc(50% - 22px)",
      },
      "&::before": {
        display: "block",
        position: "absolute",
        width: 44,
        aspectRatio: 1,
        backgroundColor: theme.palette.wedding.accent,
        content: "''",
        borderRadius: "50%",
        top: "calc(50% - 22px)",
        opacity: 1,
      },
      "&::after": {
        width: 16,
        aspectRatio: 1,
        transformOrigin: "50% 50%",
        borderTop: `3px solid #858484`,
        borderRight: `3px solid #858484`,
        borderRadius: 3,
        content: "''",
        display: "block",
        position: "absolute",
        top: "calc(50% - 8px)",
      },
      "&.slick-prev": {
        left: 12,
        [theme.breakpoints.up("md")]: {
          left: -46,
        },
        "&::before": {
          left: -22,
        },
        "&::after": {
          transform: "rotate(-135deg)",
          left: -5,
        },
      },
      "&.slick-next": {
        right: 12,
        [theme.breakpoints.up("md")]: {
          right: -46,
        },
        "&::before": {
          right: -22,
        },
        "&::after": {
          transform: "rotate(45deg)",
          right: -5,
        },
      },
      "&.slick-disabled": {
        opacity: 0,
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.up("md")]: {
        bottom: -52,
      },
      "& li": {
        "& button": {
          width: 12,
          height: 12,
          margin: 2,
          backgroundColor: theme.palette.wedding.button,
          borderRadius: "50%",
          opacity: "0.25",
          transition: "opacity 250ms ease-in-out",
          "&::before": {
            display: "none",
          },
          "&:hover, &:focus": {
            opacity: "0.5",
          },
        },
        "&.slick-active": {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
})
