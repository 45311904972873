import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import arrowImg from '../../../images/link-arrow.png';
import phoneImg from '../../../images/icon-phone-white.png';
import ReactHtmlParser from 'react-html-parser';
import Link from "../../Link";
import Map from "./Map";

const useStyles = makeStyles(theme => styles(theme));

const url = `${process.env.GATSBY_CRM_ENDPOINT_URL}houses?fields=basic,urls,facilities`;
const headers = {
    'Accept':        'application/json',
    'Authorization': 'Bearer ' + process.env.GATSBY_CRM_ENDPOINT_AUTH_TOKEN,
    'Content-Type':  'application/json'
}

export default function InnsListing() {
    const classes = useStyles();
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        if (!data || data.length) return;

        axios.get(`${url}&perPage=500`, { headers })
        .then(res => res.data.data)
        .then(data => data.filter(pub => pub.brand).filter(pub => pub.brand.name === 'Individual Inns'))
        .then(data => setData(data));
    }, [data])

    const pubList = <Grid container spacing={2} direction="row">
        {data && data.map((pub, index) => {
            return <Grid item xs={12} md={6} lg={4} key={index}>
            <div className={classes.Card}>
                <div className={classes.CardUpper}>
                    <img src={pub.url_featured_image} className={classes.CardImage} alt={pub.name} />
                    <span className={classes.CardInfo}>
                        { pub.facility_accommodation && <p className={classes.Tenancy}>Available</p> }
                    </span>
                </div>
                <div className={classes.CardBottom}>
                    <h3 className={classes.CardName}>{ReactHtmlParser(pub.name)}</h3>
                    <p className={classes.CardLocation}>{pub.display_location}</p>
                    { pub.telephone && 
                        <p className={classes.CardPhone}>
                            <img src={phoneImg} alt="Phone" /><Link to={`tel:${pub.telephone}`}>{pub.telephone}</Link>
                        </p> 
                    }
                </div>
                { pub.pubUrl &&
                    <Link to={pub.pubUrl} className={classes.CardLink}>
                        View Pub
                        <img src={arrowImg} alt="View Pub" />
                    </Link>
                }

                { !pub.pubUrl && pub.url_website_home &&
                    <Link to={pub.url_website_home} className={classes.CardLink}>
                        View Pub
                        <img src={arrowImg} alt="View Pub" />
                    </Link>
                }
            </div>
        </Grid>
        })}
    </Grid>

    let pubs = [];
    data.map((pub) => {
        pub.crmId = pub.id;
        pub.PubData = pub;
        pubs.push(pub.PubData)
    });

    const map = (
        <Map
            pubs={pubs}
            firstPub={pubs[0]}
            wpData={data}
            classes={classes}
            className={classes.Map}
        />
    );

    const [display, setDisplay] = useState("list");
    const showList = function() {
        setDisplay("list");
    }

    const showMap = function() {
        setDisplay("map");
    }

    return <div className={classes.results}>
        <div className="list-filter">
            <span
                onClick={showList}
                className="grid"
                data-active={display === "list"}
            >
                Grid View
            </span>
            <span
                onClick={showMap}
                className="map"
                data-active={display === "map"}
            >
                Map View
            </span>
        </div>
        {display === "list" && (
            <div className={classes.grid}>
                {pubList}
            </div>
        )}
        {display === "map" && (
            <div className={classes.map} style={{ width: "100%", }} >
                {map}
            </div>
        )}
    </div>
};
