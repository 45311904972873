import arrowNext from "../../../images/arrow-next-light.png"
import arrowPrev from "../../../images/arrow-prev-light.png"

export default theme => ({
  gridSliderGallery: {
    position: "relative",
    marginBottom: 36,
    textAlign: "center",
    "& h1, & h2": {
      marginBottom: 40,
    },
    "& .gallery-slide": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      gap: "15px",
      [theme.breakpoints.up("md")]: {
        gap: "35px",
      },
      "& button": {
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        fontSize: 0,
      },
      "& img": {
        display: "block",
        aspectRatio: 1,
        objectFit: "cover",
        objectPosition: "center",
        width: "100%",
      },
    },
    "& .slick-slider": {
      marginBottom: 90,
    },
    "& .slick-arrow": {
      top: 0,
      width: "10%",
      height: "auto",
      transform: "none",
      aspectRatio: 0.1,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundColor: "transparent !important",
      backgroundPosition: "center !important",
      zIndex: 10,
      [theme.breakpoints.up("md")]: {
        aspectRatio: "none",
        width: 44,
        height: 44,
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: "50%",
        top: "calc(50% - 22px)",
      },
      "&::before": {
        display: "none",
      },
      "&.slick-prev": {
        left: 0,
        backgroundImage: `url(${arrowPrev})`,
        [theme.breakpoints.up("md")]: {
          left: -12,
        },
        [theme.breakpoints.up(1220)]: {
          left: -66,
        },
      },
      "&.slick-next": {
        right: 0,
        backgroundImage: `url(${arrowNext})`,
        [theme.breakpoints.up("md")]: {
          right: -12,
        },
        [theme.breakpoints.up(1220)]: {
          right: -66,
        },
      },
      "&.slick-disabled": {
        opacity: 0,
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.up("md")]: {
        bottom: -52,
      },
      "& li": {
        "& button": {
          width: 16,
          height: 16,
          margin: 2,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "50%",
          opacity: "0.25",
          transition: "opacity 250ms ease-in-out",
          cursor: "pointer",
          "&::before": {
            display: "none",
          },
          "&:hover, &:focus": {
            opacity: "0.5",
          },
        },
        "&.slick-active": {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
  imagePopUp: {
    position: "fixed",
    top: "10vh",
    height: "80vh",
    left: "50%",
    width: "90vw",
    transform: "translateX(-50%)",
    zIndex: 999,
    maxWidth: 1130,
    padding: 17,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      zIndex: 10,
      "& .caption": {
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: 0,
        overflow: "hidden",
        display: "block",
        width: "100%",
        paddingTop: 20,
        "& p": {
          padding: "0 12px 6px 12px",
          left: 0,
          color: "white",
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          boxShadow: "0 0 12px 12px rgba(0,0,0,0.5)",
          textShadow: "0 0 2px black",
          fontSize: 12,
          letterSpacing: "0.032em",
          textAlign: "right",
        },
      },
    },
    "& button.close": {
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "100vw",
      height: "100vh",
      transform: "translate(-50%, -50%)",
      border: "none",
      background: "rgba(0,0,0,0.45)",
      fontSize: 0,
      cursor: "pointer",
    },
    "& button.close-x": {
      position: "absolute",
      top: "12px",
      right: "12px",
      width: "44px",
      height: "44px",
      border: "none",
      background: "transparent",
      fontSize: 0,
      zIndex: 20,
      cursor: "pointer",
      filter: "drop-shadow(0 0 5px black)",
      "&::before, &::after": {
        content: '""',
        display: "block",
        width: 4,
        height: 30,
        transformOrigin: "50% 50%",
        position: "absolute",
        backgroundColor: "white",
        top: 40,
        right: 40,
      },
      "&::before": {
        transform: "rotate(-45deg)",
      },
      "&::after": {
        transform: "rotate(45deg)",
      },
    },
    "& button.next, & button.prev": {
      backgroundSize: "24px",
      backgroundRepeat: "no-repeat",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      zIndex: 30,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      border: "none",
      fontSize: 0,
      cursor: "pointer",
      [theme.breakpoints.up("md")]: {
        aspectRatio: "none",
        width: 44,
        height: 44,
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: "50%",
        top: "calc(50% - 22px)",
      },
    },
    "& button.prev": {
      left: 12,
      backgroundImage: `url(${arrowPrev})`,
      [theme.breakpoints.up("md")]: {
        left: -44,
      },
    },
    "& button.next": {
      right: 12,
      backgroundImage: `url(${arrowNext})`,
      [theme.breakpoints.up("md")]: {
        right: -44,
      },
    },
  },
})
