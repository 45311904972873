export default theme => ({
  twoColumnStacked: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "calc(50% - 24px) calc(50% - 24px)",
      gridTemplateRows: "repeat(5, auto)",
      gridColumnGap: "48px",
      marginBottom: 72,
    },
    "& h1, h2": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 40,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
        gridArea: "1 / 1 / 2 / 3",
      },
    },
    "& .gatsby-image-wrapper": {
      width: "calc(100% + 36px)",
      marginLeft: -18,
      [theme.breakpoints.up("md")]: {
        width: "100%",
        marginLeft: 0,
        "&:nth-of-type(1)": {
          gridArea: "2 / 1 / 3 / 2",
        },
      },
    },
    "& h3": {
      marginBottom: 32,
      fontFamily: "Libre Baskerville",
      fontWeight: "normal",
      fontSize: 26,
      [theme.breakpoints.up("md")]: {
        marginBottom: 20,
        fontSize: 36,
        margin: "60px 0 32px",
        "&:nth-of-type(1)": {
          gridArea: "3 / 1 / 4 / 2",
        },
      },
    },
    "& p": {
      fontSize: 13,
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 17,
        "&:nth-of-type(1)": {
          gridArea: "4 / 1 / 5 / 2",
        },
      },
    },
    "& > a": {
      margin: "0 auto 36px",
      display: "block",
      width: "fit-content",
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
        "&:nth-of-type(1)": {
          gridArea: "5 / 1 / 6 / 2",
        },
      },
      "& button": {
        minWidth: 174,
        backgroundColor: theme.palette.wedding.button,
        color: "white",
        fontFamily: "Libre Baskerville",
        fontSize: 15,
        fontWeight: "normal",
        padding: 8,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: "none",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.wedding.button,
          textTransform: "0 0 4px 4px rgba(0,0,0.25)",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 17,
          borderRadius: 6,
          padding: 15,
          minWidth: 244,
        },
      },
    },
  },
})
