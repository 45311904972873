import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import weddingStyles from "./weddingStyles"
import Slider from "react-slick/lib"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfGridSliderGalleryBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const [open, setOpen] = React.useState(0)
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  const sliderRef = useRef(null)

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  const settings = {
    dots: true,
    infinite: false,
    centerMode: false,
    ref: sliderRef,
    // lazyLoad: true,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    // focusOnSelect: true,
  }

  let images = props.block.blockGridSliderGallery.gallery
  let endImages = []
  let count = 0
  let temp = []

  images.map(image => {
    if (count < 9) {
      temp.push(image)
    }
    count++
    if (count === 9) {
      endImages.push(temp)
      temp = []
      count = 0
    }
  })

  if (temp.length > 0) {
    endImages.push(temp)
  }

  return (
    <>
      <div class={`grid-slider-gallery ${useClasses.gridSliderGallery}`}>
        {blocks[0]}
        <div class="gallery">
          <Slider {...settings}>
            {endImages.map(images => {
              return (
                <div className={`gallery-slide`}>
                  {images.map(image => {
                    return (
                      <button
                        onClick={() => {
                          setOpen(0)
                          setOpen(image.id)
                        }}
                        onKeyDown={() => {
                          setOpen(0)
                          setOpen(image.id)
                        }}
                      >
                        <img
                          src={
                            image.mediaItemUrlSharp
                              ? image.mediaItemUrlSharp.publicURL
                              : image.mediaItemUrl
                          }
                          alt={image.altText}
                        />
                      </button>
                    )
                  })}
                </div>
              )
            })}
          </Slider>
        </div>
        {blocks[1]}
      </div>
      {images.map((image, index) => {
        if (open === image.id) {
          return (
            <div className={useClasses.imagePopUp}>
              <button
                className={`close`}
                onClick={() => setOpen(0)}
                onKeyDown={() => setOpen(0)}
              >
                x
              </button>
              <div>
                {image.caption && (
                  <figcaption className="caption">
                    <p>
                      {image.caption.replace("<p>", "").replace("</p>", "")}
                    </p>
                  </figcaption>
                )}
                <button
                  className={`close-x`}
                  onClick={() => setOpen(0)}
                  onKeyDown={() => setOpen(0)}
                >
                  x
                </button>
                {index !== 0 && (
                  <button
                    className={"prev"}
                    onClick={() => {
                      setOpen(images[index - 1].id)
                      if (index % 9 === 0) {
                        sliderRef.current.slickPrev()
                      }
                    }}
                  >
                    Previous
                  </button>
                )}
                <img
                  src={
                    image.mediaItemUrlSharp
                      ? image.mediaItemUrlSharp.publicURL
                      : image.mediaItemUrl
                  }
                  alt={image.altText}
                />
                {index < images.length - 1 && (
                  <button
                    className={"next"}
                    onClick={() => {
                      setOpen(images[index + 1].id)
                      if ((index + 1) % 9 === 0) {
                        sliderRef.current.slickNext()
                      }
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )
        }
      })}
    </>
  )
}

AcfGridSliderGalleryBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfGridSliderGalleryBlockFragment = graphql`
  fragment AcfGridSliderGalleryBlock on wp_Block {
    ... on wp_AcfGridSliderGalleryBlock {
      name
      attributes {
        className
      }
      blockGridSliderGallery {
        gallery {
          id
          altText
          caption
          mediaItemUrlSharp {
            id
            publicURL
          }
          mediaDetails {
            height
            width
          }
          mediaItemUrl
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfGridSliderGalleryBlockPreview = `
... on AcfGridSliderGalleryBlock { 
  name 
  attributes { 
    className 
  } 
  blockGridSliderGallery { 
    gallery { 
      id
      altText
      caption
      mediaItemUrl 
    }
  } 
}`
