import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  gallery: {
    position: "relative",
    margin: "0 -8px !important",
    width: "calc(100% + 16px)",
    "& li": {
      float: "left",
      padding: "0 8px !important",
    },
  },
}))

export const AcfBrewGalleryBlock = props => {
  const classes = useStyles()
  const numberOfColumns = props.block.blockBrewGallery.numberOfColumns

  let images = props.block.blockBrewGallery.brewGallery

  return (
    <GridList
      cellHeight={"auto"}
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.gallery}`}
      cols={numberOfColumns ? numberOfColumns : 2}
    >
      {images.map(image => (
        <GridListTile key={image.id}>
          {image.mediaItemUrlSharp && (
            <div className="gatsby-image-wrapper blocks-gallery-item">
              <div
                style={{
                  paddingBottom:
                    (image.mediaDetails.height / image.mediaDetails.width) *
                      100 +
                    "%",
                }}
              />
              <img
                src={image.mediaItemUrlSharp.publicURL}
                alt={image.altText}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            </div>
          )}
          {!image.mediaItemUrlSharp && (
            <div className="gatsby-image-wrapper blocks-gallery-item">
              <img src={image.mediaItemUrl} alt={image.altText} />
            </div>
          )}
        </GridListTile>
      ))}
    </GridList>
  )
}

AcfBrewGalleryBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfBrewGalleryBlockFragment = graphql`
  fragment AcfBrewGalleryBlock on wp_Block {
    ... on wp_AcfBrewGalleryBlock {
      name
      attributes {
        className
      }
      blockBrewGallery {
        brewGallery {
          id
          altText
          mediaItemUrlSharp {
            id
            publicURL
          }
          mediaDetails {
            height
            width
          }
          mediaItemUrl
        }
        numberOfColumns
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBrewGalleryBlockPreview = `
... on AcfBrewGalleryBlock { 
    name 
    attributes { 
        className 
    } 
    blockBrewGallery { 
        brewGallery { 
            id
            altText
            mediaItemUrl 
        } 
        numberOfColumns 
    } 
}`
