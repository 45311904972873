export default theme => ({
  iconRowInner: {
    position: "relative",
    "& img, & .gatsby-image-wrapper": {
      width: 46,
      height: 46,
      margin: "0 auto 16px",
      display: "block",
    },
    "& p": {
      marginBottom: 20,
      [theme.breakpoints.up("md")]: {
        marginBottom: 32,
      },
    },
  },
})
