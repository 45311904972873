import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography/Typography"
import Button from "@material-ui/core/Button/Button"
import Img from "gatsby-image"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CgbLatestPostsBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query LatestPostsList {
      wp {
        themeOptions {
          blogPath
        }
        posts {
          nodes {
            slug
            title
            categories {
              nodes {
                categoryId
              }
            }
            featuredImage {
              node {
                mediaItemUrl
                mediaItemUrlSharp {
                  id
                  publicURL
                  childImageSharp {
                    fluid(quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let tempPosts = []

  data.wp.posts.nodes.map(post => {
    if ("0" !== props.attributes.catID) {
      post.categories.nodes.map(cat => {
        if (cat.categoryId == props.attributes.catID) {
          tempPosts.push(post)
        }
      })
    } else {
      tempPosts.push(post)
    }
  })

  let image = null

  // Use the gatsby image if available
  if (tempPosts[0].featuredImage) {
    if (tempPosts[0].featuredImage.node.mediaItemUrlSharp) {
      if (tempPosts[0].featuredImage.node.mediaItemUrlSharp.childImageSharp) {
        image = (
          <Img
            fluid={
              tempPosts[0].featuredImage.node.mediaItemUrlSharp.childImageSharp
                .fluid
            }
            alt=""
          />
        )
      } else {
        // Gifs do not have a childImageSharp
        image = (
          <img
            src={tempPosts[0].featuredImage.node.mediaItemUrlSharp.publicURL}
            alt=""
          />
        )
      }
    } else {
      // This is a preview image
      image = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingBottom: "55%" }} />
          <img
            src={tempPosts[0].featuredImage.node.mediaUrl}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      )
    }
  }

  return (
    <div
      className={`latest-posts ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.latestPosts} ${classes.latestPostsTheme}`}
    >
      <Typography component="h2" variant="h2" color="initial" align="left">
        {props.attributes.sectionTitle}
      </Typography>
      <div className="latest-post-grid">
        <div className="newest">
          <Link
            to={
              "/" +
              data.wp.themeOptions.blogPath +
              "/" +
              tempPosts[0].slug +
              "/"
            }
            className="more-link"
          >
            {image}
            <span className="title">{tempPosts[0].title}</span>
            <span className="more">More</span>
          </Link>
        </div>
        <div className="split" />
        <div className="more">
          {tempPosts[1] ? (
            <>
              <Link
                to={
                  "/" +
                  data.wp.themeOptions.blogPath +
                  "/" +
                  tempPosts[1].slug +
                  "/"
                }
                className="more-link"
              >
                {tempPosts[1].title}
              </Link>
              <hr />
            </>
          ) : (
            ""
          )}

          {tempPosts[2] ? (
            <>
              <Link
                to={
                  "/" +
                  data.wp.themeOptions.blogPath +
                  "/" +
                  tempPosts[2].slug +
                  "/"
                }
                className="more-link"
              >
                {tempPosts[2].title}
              </Link>
              <hr />
            </>
          ) : (
            ""
          )}

          {tempPosts[3] ? (
            <>
              <Link
                to={
                  "/" +
                  data.wp.themeOptions.blogPath +
                  "/" +
                  tempPosts[3].slug +
                  "/"
                }
                className="more-link"
              >
                {tempPosts[3].title}
              </Link>
              <hr />
            </>
          ) : (
            ""
          )}

          <Link
            to={"/" + data.wp.themeOptions.blogPath + "/"}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary">
              {props.attributes.btnText}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

CgbLatestPostsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbLatestPostsBlockFragment = graphql`
  fragment CgbLatestPostsBlock on wp_Block {
    ... on wp_CgbLatestPostsBlock {
      name
      attributes {
        __typename
        ... on wp_CgbLatestPostsBlockAttributes {
          className
          sectionTitle
          btnText
          catID
        }
      }
    }
  }
`

export const CgbLatestPostsBlockPreview = `
... on CgbLatestPostsBlock {
  name
  attributes {
    __typename
    ... on CgbLatestPostsBlockAttributes {
      className
      sectionTitle
      btnText
      catID
    }
  }
}
`
