export default theme => ({
    openingTimes: {
        marginBottom: 36,
        '& .inner': {
            backgroundColor: theme.palette.secondary.main,
            width: '100vw',
            maxWidth: 'calc(100% + 34px)',
            padding: '50px 24px',
            margin: '0 -17px',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& .title': {
                '& h2': {
                    fontFamily: theme.subHeaderFont,
                    fontSize: 24,
                    lineHeight: '1.2',
                    marginBottom: 60,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.contrastText,
                    letterSpacing: '0.06em',
                    [theme.breakpoints.up('md')]: {
                        maxWidth: 300,
                        fontSize: 32,
                        lineHeight: '1',
                        marginBottom: 0,
                    },
                },
            },
            '& .times': {
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'row',
                    borderLeft: '1px solid',
                    borderLeftColor: theme.palette.primary.main,
                    paddingLeft: 24,
                    marginLeft: 24,
                },
                '& .select': {
                    marginBottom: 36,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: 0,
                    }
                },
                '& p': {
                    color: theme.palette.secondary.contrastText,
                    fontSize: 8,
                    lineHeight: '2',
                    letterSpacing: '.152em',
                    textTransform: 'uppercase',
                    marginBottom: 12,
                    [theme.breakpoints.up('md')]: {
                        lineHeight: '1',
                    },
                },
                '& .MuiFormControl-root': {
                    marginBottom: 30,
                },
                '& .MuiFormControl-root, & .MuiInputBase-root': {
                    width: '100%',
                    marginBottom: 0,
                    [theme.breakpoints.up('md')]: {
                        width: 180,
                    },
                },
                '& .MuiSelect-root': {
                    backgroundColor: theme.palette.secondary.contrastText,
                    width: '100%',
                    fontSize: 14,
                    fontFamily: theme.headerFont,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    padding: 10,
                },
                '& .time-block': {
                    textAlign: 'center',
                    color: theme.palette.secondary.contrastText,
                    marginBottom: 16,
                    display: 'none',
                    '&[data-active="true"]': {
                        display: 'block',
                    },
                    [theme.breakpoints.up('md')]: {
                        textAlign: 'right',
                        marginBottom: 0,
                    },
                    '& .type': {
                        fontSize: 14,
                        lineHeight: '1.9',
                        letterSpacing: '0.016em',
                        paddingRight: 24,
                        [theme.breakpoints.up('md')]: {
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'right',
                            paddingLeft: 36,
                            fontSize: 16,
                            lineHeight: '1.5',
                        },
                    },
                    '& .breakdown': {
                        fontSize: 14,
                        lineHeight: '1.9',
                        letterSpacing: '0.016em',
                        fontWeight: '600',
                        display: 'inline-block',
                        [theme.breakpoints.up('md')]: {
                            verticalAlign: 'top',
                            fontSize: 16,
                            lineHeight: '1.5',
                            width: 260,
                            textAlign: 'left',
                        },
                        '& span': {
                            display: 'inline-block',
                            '&:before': {
                                content: '"|"',
                                margin: '0 8px',
                            },
                            '&:first-child': {
                                '&:before': {
                                    display: 'none',
                                }
                            }
                        },
                    }
                }
            }
        }
    },
    openingTimesTheme: {
        ...theme.openingTimesTheme,
    }
})
