import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'block',
    position: 'relative',
    marginBottom: 30,
    width: 'calc(100% + 12px)',
    marginLeft: -6,
    marginRight: -6,
    '& > a': {
        margin: 6,
    }
  },
}));

export const CoreButtonsBlock = (props) => {
    const classes = useStyles();
  
    const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);

    let layout = {};

    if(props.attributes.layout !== null) {
      layout = (JSON.parse(props.attributes.layout));
    } else {
      layout = {
        type: 'flex',
        justifyContent: 'flex-start',
      }
    }

    return <div 
      className={`button-group ${props.attributes.className ? props.attributes.className : ''} ${classes.buttons}`}
      style={{display: layout.type, justifyContent: layout.justifyContent}}  
    >
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

CoreButtonsBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreButtonsBlockFragment = graphql`
  fragment CoreButtonsBlock on wp_Block {
    ... on wp_CoreButtonsBlock {
      name
      attributes {
        __typename
       ... on wp_CoreButtonsBlockAttributes {
          className
          align
          layout
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreButtonsBlockPreview = `
... on CoreButtonsBlock {
    name
    attributes {
      __typename
     ... on CoreButtonsBlockAttributes {
        className
        align
        layout
      }
    }
  }
`;