import React from "react"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import { transformLink } from "../../../helpers/"
import { graphql } from "gatsby"

const useStyles = wedding =>
  makeStyles(theme => ({
    para: {
      display: "block",
      position: "relative",
      fontFamily: wedding ? "Libre Baskerville" : theme.bodyFont,
      "& a": {
        color: theme.palette.secondary.main,
        "&:hover, &:foucs": {
          color: theme.palette.secondary.dark,
        },
      },
    },
  }))

export const CoreParagraphBlock = props => {
  const classes = useStyles(props.pageContext.wedding)()

  let style = {}

  return (
    <Typography
      variant="body1"
      color={
        props.attributes.textColor !== null
          ? props.attributes.textColor
          : "initial"
      }
      align={props.attributes.align !== null ? props.attributes.align : "left"}
      className={props.attributes.className + " " + classes.para}
      style={style}
    >
      {ReactHtmlParser(props.attributes.content, { transform: transformLink })}
    </Typography>
  )
}

CoreParagraphBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreParagraphBlockFragment = graphql`
  fragment CoreParagraphBlock on wp_Block {
    ... on wp_CoreParagraphBlock {
      name
      attributes {
        __typename
        ... on wp_CoreParagraphBlockAttributes {
          backgroundColor
          textColor
          className
          align
          direction
          content
        }
      }
    }
  }
`

export const CoreParagraphBlockPreview = `
  ... on CoreParagraphBlock {
    name
    attributes {
      __typename
      ... on CoreParagraphBlockAttributes {
        backgroundColor
        textColor
        className
        align
        direction
        content
      }
    }
  }
  `
