import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import Link from "../../Link";
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbContactBannerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    // if( ! props.attributes.mediaUrl) return null;
    let image = null;

    // Use the gatsby image if available
    if(props.attributes.mediaUrlSharp){
        if(props.attributes.mediaUrlSharp.childImageSharp){
            image = <Img
                className="desktop-bg"
                fluid={props.attributes.mediaUrlSharp.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                className="desktop-bg"
                src={props.attributes.mediaUrlSharp.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-bg">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }
    let mobileImage = null;

    // Use the gatsby image if available
    if(props.attributes.mediaUrlMobileSharp){
        if(props.attributes.mediaUrlMobileSharp.childImageSharp){
            mobileImage = <Img
                className="mobile-bg"
                fluid={props.attributes.mediaUrlMobileSharp.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            mobileImage = <img
                className="mobile-bg"
                src={props.attributes.mediaUrlMobileSharp.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        mobileImage = <div className="gatsby-image-wrapper mobile-bg">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrlMobile}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }
    const data = useStaticQuery(graphql`
      query ContactInfo {
        wp {
          locationData {
            LocationData {
                address1
                address2
                email
                name
                postcode
                town
                telephone
                region
                googlePlaceId
                latitude
                longitude
            }
          }
        }
      }
    `);

    const locationData = data.wp.locationData.LocationData;
    let directionsUrl = 'https://www.google.com/maps/dir/?api=1&destination=' + (locationData.googlePlaceId ? `${encodeURI(locationData.name)}&destination_place_id=${locationData.googlePlaceId}` : `${locationData.latitude},${locationData.longitude}`);

    return <div
        className={`contact-banner ${props.attributes.className ? props.attributes.className : ''} ${classes.contactBanner} ${classes.contactBannerTheme}`}
    >
        <div className="page-banner">
            {blocks.map((block) => {
                return block;
            })}
        </div>
        <div className="map-block">
            <Link to={directionsUrl} className="map-link">
                {image}
                {mobileImage}
            </Link>
            <div className="contact-wrap">
                <div className="location-data">
                    <p className="location-name">{locationData.name}</p>
                    <p>
                        {locationData.address1 !== null &&
                            <span>{locationData.address1}, </span>
                        }
                        {locationData.address2 !== null &&
                            <span>{locationData.address2}, </span>
                        }
                        {locationData.town !== null &&
                            <span>{locationData.town}, </span>
                        }
                        {locationData.region !== null &&
                            <span>{locationData.region}, </span>
                        }
                        <br/>
                        {locationData.postcode !== null &&
                            <span>{locationData.postcode} </span>
                        }
                    </p>
                </div>
                <Link to={directionsUrl} className="map-link"><RoomIcon/><span>Get Directions</span></Link>
                <Link to={"tel:" + locationData.telephone} className="tel-link"><PhoneEnabledIcon/><span>{locationData.telephone}</span></Link>
                <Link to={"mailto:" + locationData.email} className="email-link"><EmailIcon/><span>Email us</span></Link>
            </div>
            <div className="copyright">
                &copy; <Link to="https://www.openstreetmap.org/copyright">OpenStreetMap</Link> contributors
            </div>
        </div>
    </div>
};

CgbContactBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbContactBannerBlockFragment = graphql`
    fragment CgbContactBannerBlock on wp_Block {
        ... on wp_CgbContactBannerBlock {
            name
            attributes {
                __typename
                ... on wp_CgbContactBannerBlockAttributes {
                    className
                    mediaUrl
                    mediaUrlSharp {
                        id
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    mediaUrlMobile
                    mediaUrlMobileSharp {
                        id
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbContactBannerBlockPreview = `
    ... on CgbContactBannerBlock {
        name
        attributes {
            __typename
            ... on CgbContactBannerBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;
