import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbPullquoteBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    // if( ! props.attributes.mediaUrl) return null;
    let image = null;

    // Use the gatsby image if available
    if(props.attributes.mediaUrlSharp){
        if(props.attributes.mediaUrlSharp.childImageSharp){
            image = <Img
                fluid={props.attributes.mediaUrlSharp.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                src={props.attributes.mediaUrlSharp.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.pullquote} ${classes.pullquoteTheme}`}
    >
        {image}
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

CgbPullquoteBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbPullquoteBlockFragment = graphql`
    fragment CgbPullquoteBlock on wp_Block {
        ... on wp_CgbPullquoteBlock {
            name
            attributes {
                __typename
                ... on wp_CgbPullquoteBlockAttributes {
                    className
                    mediaUrl
                    mediaUrlSharp {
                        id
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }    
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbPullquoteBlockPreview = `
    ... on CgbPullquoteBlock {
        name
        attributes {
            __typename
            ... on CgbPullquoteBlockAttributes {
                className
                mediaUrl  
            }
        }
    }
`;