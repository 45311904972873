export default theme => ({
    imageBreakdown: {
        position: 'relative',
        marginBottom: 36,
        // margin: '80px 0',
        [theme.breakpoints.up('md')]: {
            // margin: '140px 0 175px',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            marginBottom: 50,
        },
        '& .gatsby-image-wrapper': {
            marginBottom: '0',
            '& > div': {
                paddingBottom: '160% !important',
            }
        },
        '& .image-wrap': {
            padding: '12px 0',maxWidth: 'calc(100% + 10px)',
            flexBasis: 'calc(100% + 10px)',
            marginLeft: -5,
            marginRight: -5,
            [theme.breakpoints.up('md')]: {
                padding: 12,
                maxWidth: '33.33333%',
                flexWidth: '33.33333%',
                marginLeft: 0,
                marginRight: 0
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '41.66666%',
                flexWidth: '41.66666%',
            },
        },
        '& .left-con': {
            '& .MuiTypography-root': {
                [theme.breakpoints.up('md')]: {
                    textAlign: 'right',
                }
            }
        },
        '& .title-wrap': {
            '& h1, & h2, & h3, & h4, & h5, & h6' : {
                textAlign: 'left !important',
                [theme.breakpoints.up('md')]: {
                    textAlign: 'center !important',
                }
            }
        }
    },
    imageBreakdownTheme: {
        ...theme.imageBreakdownTheme
    },
})