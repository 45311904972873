export default theme => ({
    thickIntro: {
        position: 'relative',
        marginBottom: 36,
        // padding: '50px 0 70px',
        [theme.breakpoints.up('md')]: {
            // padding: '180px 0 150px',
        },
        '& p': {
            lineHeight: 1.75,
        },
        '&.pub-intro': {
            '& h1, & h2, & h3, & h4, & h5, & h6' : {
                [theme.breakpoints.up('md')]: {
                    fontSize: 48,
                    letterSpacing: '0.06em',
                },
            },
            '& p': {
                maxWidth: 450,
            },
        }
    },
    thickIntroTheme: {
        ...theme.thickIntroTheme,
    }
})