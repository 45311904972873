import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfTestimonialSliderInnerBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div
      className={`testimonial-slider-inner ${useClasses.testimonialSliderInner}`}
    >
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs={12} md={6} lg={7}>
          {blocks[0]}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          {blocks[1]}
          {blocks[2]}
          {blocks[3]}
        </Grid>
      </Grid>
    </div>
  )
}

export const AcfTestimonialSliderInnerBlockFragment = graphql`
  fragment AcfTestimonialSliderInnerBlock on wp_Block {
    ... on wp_AcfTestimonialSliderInnerBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTestimonialSliderInnerBlockPreview = `
... on AcfTestimonialSliderInnerBlock {
  name
}
`
