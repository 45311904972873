import bestRatesGif from '../../../images/Search-animation-v02.gif';

export default theme => ({
    accommodationListing: {
        position: 'relative',
        marginBottom: 36,
        // padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            // padding: '140px 0',
        },
        '& .MuiGrid-container': {
            marginBottom: 30,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
            },
            '&::before': {
                height: 2,
                margin: '0 auto 20px',
                backgroundColor: theme.palette.secondary.main,
                content: '""',
                display: 'block',
                width: 'calc( 100% - 24px )',
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                }
            },
            '&:nth-of-type(1)::before': {
                display: 'none',
            }
        },
        '& .MuiGrid-container:nth-of-type(odd)': {
            flexDirection: 'row-reverse',
        },
        '&. gatsby-image-wrapper, & img': {
            marginBottom: 0,
        },
        '& h2' : {
            color: theme.palette.secondary.main,
            fontSize: 32,
            lineHeight: 1.5,
            letterSpacing: '-0.008em',
            marginBottom: 10,
            fontWeight: '400',
            marginTop: 0,
        },
        '& p' : {
            fontSize: 14,
            letterSpacing: '0.032em',
            lineHeight: '1.6',
            marginBottom: 15,
            '&.rates': {
                textTransform: 'uppercase',
                fontSize: 12,
                lineHeight: 1.3,
                letterSpacing: '0.1em',
                backgroundImage: 'url(' + bestRatesGif + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top right',
                paddingTop: 15,
                paddingRight: 180,
                maxWidth: 320,
                [theme.breakpoints.up('lg')]: {
                    letterSpacing: '0.152em',
                },
                '& .price' : {
                    color: theme.palette.secondary.main,
                    fontSize: 64,
                    lineHeight: 1.2,
                    letterSpacing: '-0.008em',
                    fontFamily: theme.headerFont,
                    display: 'block',
                }
            }
        },
        '& .actions': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
            }
        },
        '& button': {
            fontFamily: theme.headerFont,
            padding: '12px 22px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 400,
            textAlign: 'center',
            minWidth: 185,
            textTransform: 'uppercase',
            width: '100%',
            letterSpacing: '0.03em',
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            }
        },
        '& .cta': {
            display: 'table',
            width: 'auto',
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontFamily: theme.headerFont,
            padding: '5px 0',
            fontSize: 16,
            lineHeight: 1.2,
            fontWeight: 400,
            textAlign: 'center',
            textTransform: 'uppercase',
            borderBottom: '4px solid',
            borderColor: theme.palette.primary.main,
            margin: '0 auto 25px',
            letterSpacing: '0.03em',
            transition: 'color 250ms ease-in-out, border-color 250ms ease-in-out',
            '&:hover, &:focus': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
            [theme.breakpoints.up('lg')]: {
                margin: 0,
            }
        }
    },
    accommodationListingTheme: {
        ...theme.accommodationListingTheme,
    }
})