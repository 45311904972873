import React from "react"
import PropTypes from "prop-types"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import { transformLink } from "../../../helpers/"
import ReactHtmlParser from "react-html-parser"
import { makeStyles } from "@material-ui/styles"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  imageCaption: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    letterSpacing: "-0.008em",
    fontSize: 32,
    lineHeight: "1.2",
    fontFamily: theme.headerFont,
    color: theme.palette.secondary.contrastText,
    padding: "56px 24px",
    marginBottom: 0,
    [theme.breakpoints.up("md")]: {
      letterSpacing: "0.007em",
      fontSize: 34,
      lineHeight: "1.25",
      padding: "75px 60px",
    },
  },
  imageCaptionTheme: {
    ...theme.imageCaptionTheme,
  },
}))

export const CoreImageBlock = props => {
  const classes = useStyles()
  if (!props.attributes.url) return null
  let image = null

  // Use the gatsby image if available
  if (props.attributes.urlSharp) {
    if (props.attributes.urlSharp.childImageSharp) {
      image = (
        <Img
          fluid={props.attributes.urlSharp.childImageSharp.fluid}
          alt={props.attributes.alt ? props.attributes.alt : undefined}
          className={`${
            props.attributes.align ? `img-align-${props.attributes.align}` : ""
          } ${props.attributes.className ? props.attributes.className : ""}`}
          width={props.attributes.width ? props.attributes.width : undefined}
          height={props.attributes.height ? props.attributes.height : undefined}
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          src={props.attributes.urlSharp.publicURL}
          alt={props.attributes.alt ? props.attributes.alt : undefined}
          className={`${
            props.attributes.align ? `img-align-${props.attributes.align}` : ""
          } ${props.attributes.className ? props.attributes.className : ""}`}
          width={props.attributes.width ? props.attributes.width : undefined}
          height={props.attributes.height ? props.attributes.height : undefined}
        />
      )
    }
  } else {
    // console.log(props.attributes.height);
    // This is a preview image
    const dimensions = getDimensions(props.attributes.url)

    image = (
      <div
        className="gatsby-image-wrapper"
        style={{
          display: "flex",
        }}
      >
        {/* <div style={{ paddingBottom: "100%" }} /> */}
        <img
          src={props.attributes.url}
          alt={props.attributes.alt ? props.attributes.alt : ""}
          className={`img-align-${props.attributes.align} ${classes.root} ${
            props.attributes.className ? props.attributes.className : ""
          }`}
          width={props.attributes.width < 1 ?? dimensions.width ?? ""}
          height={props.attributes.height < 1 ?? dimensions.height ?? ""}
          style={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            width: "100%",
            // height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    )
  }

  if (props.attributes.caption) {
    image = (
      <span className="image-with-caption">
        {image}
        <Typography
          component="p"
          variant="body1"
          className={`caption ${classes.imageCaption} ${classes.imageCaptionTheme}`}
          paragraph={true}
        >
          {ReactHtmlParser(props.attributes.caption, {
            transform: transformLink,
          })}
        </Typography>
      </span>
    )
  }

  if (props.attributes.href) {
    return (
      <Link
        to={props.attributes.href}
        title={props.attributes.title ? props.attributes.title : ""}
        className={props.attributes.linkClass ? props.attributes.linkClass : ""}
      >
        {image}
      </Link>
    )
  } else {
    return image
  }
}

CoreImageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on wp_Block {
    ... on wp_CoreImageBlock {
      name
      attributes {
        __typename
        ... on wp_CoreImageBlockAttributes {
          align
          alt
          caption
          className
          height
          href
          id
          linkClass
          linkDestination
          linkTarget
          rel
          sizeSlug
          title
          url
          width
          urlSharp {
            id
            publicURL
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export const CoreImageBlockPreview = `
... on CoreImageBlock {
  name
  attributes {
    __typename
    ... on CoreImageBlockAttributes {
      align
      alt
      caption
      className
      height
      href
      id
      linkClass
      linkDestination
      linkTarget
      rel
      sizeSlug
      title
      url
      width
    }
  }
}
`

const getDimensions = url => {
  // parse url string, look for width and height e.g 1000x500
  const dimensions = url.match(/\d{2,4}x\d{2,4}/g)
  // if no dimensions found, return null
  if (!dimensions) return null
  // split dimensions into array
  const dimensionsArray = dimensions[0].split("x")
  // return object with width and height
  return {
    width: dimensionsArray[0],
    height: dimensionsArray[1],
  }
}
