export default theme => ({
    blogPagePosts: {
        position: 'relative',
        paddingBottom: 50,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
        },
        marginBottom: 36,
        '& .blog-list-banner': {
            padding: '100px 0',
            marginBottom: '-65px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                padding: '200px 0 160px',
                marginBottom: '-78px',
            },
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: theme.palette.secondary.main,
                top: 0,
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50vw)',
            },
            '& h1': {
                position: 'relative',
                zIndex: '1',
                color: theme.palette.secondary.contrastText,
                marginBottom: 0,
            }
        },
        '& .blog-list': {
            position: 'relative',
            zIndex: '1',
            '& .featured-item': {
                '& a': {
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    // padding: 24,
                    height: 265,
                    display: 'block',
                    borderRadius: 10,
                    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                    marginBottom: 30,
                    textDecoration: 'none',
                    overflow: 'hidden',
                    [theme.breakpoints.up('md')]: {
                        // padding: '50px 55px',
                        height: 485,
                    },
                    '& .gatsby-image-wrapper': {
                        '& > div': {
                            paddingBottom: '265px !important',
                            [theme.breakpoints.up('md')]: {
                                paddingBottom: '485px !important',
                            },
                        }
                    },
                    '&::before': {
                        position: 'absolute',
                        zIndex: '1',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%)',
                        opacity: '0.6',
                        content: '""',
                        [theme.breakpoints.up('md')]: {
                            background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,.58) 33%,rgba(0,0,0,0) 100%)',
                        },
                    },
                    '& .cta-wrap': {
                        position: 'absolute',
                        top: 24,
                        left: 24,
                        right: 24,
                        zIndex: '1',
                        [theme.breakpoints.up('md')]: {
                            top: 50,
                            left: 55,
                            right: 50,
                        },
                    },
                    '& .date': {
                        fontSize: 10,
                        textTransform: 'uppercase',
                        letterSpacing: '0.152em',
                        opacity: .57,
                        color: 'white',
                        marginBottom: 16,
                        display: 'block',
                    },
                    '& .post-title': {
                        fontFamily: theme.headerFont,
                        fontSize: 20,
                        lineHeight: 1.15,
                        letterSpacing: '-0.008em',
                        marginBottom: 24,
                        color: 'white',
                        display: 'block',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 38,
                            lineHeight: 1.3,
                        },
                    },
                    '& .cta': {
                        borderBottom: '2px solid white',
                        fontFamily: theme.headerFont,
                        fontSize: 16,
                        lineHeight: 1.4,
                        textTransform: 'uppercase',
                        color: 'white',
                        display: 'inline-block',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 18,
                        },
                    }
                }
            },
            '& .list-item:nth-of-type(1)': {
                display: 'none',
            },
            '& .list-item': {
                border: '1px solid rgba(0,0,0,0.12)',
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                marginBottom: 30,
                borderRadius: 10,
                [theme.breakpoints.up('md')]: {
                    boxShadow: 'none',
                },
                '& .gatsby-image-wrapper': {
                    borderRadius: 10,
                    overflow: 'hidden',
                    '& > div': {
                        paddingBottom: '78% !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: 330,
                        float: 'left',
                    }
                },
                '& .con-wrap': {
                    padding: '24px 35px',
                    [theme.breakpoints.up('md')]: {
                        width: 'calc(100% - 330px)',
                        float: 'left',
                    },
                    '& .date': {
                        fontSize: 8,
                        textTransform: 'uppercase',
                        letterSpacing: '0.152em',
                        opacity: .57,
                        marginBottom: 12,
                        marginTop: 0,
                    },
                    '& h2': {
                        color: theme.palette.secondary.main,
                        fontFamily: theme.headerFont,
                        fontSize: 24,
                        lineHeight: 1.4,
                        marginBottom: 16,
                        marginTop: 0,
                        letterSpacing: '-0.008em',
                        fontWeight: '400',
                    },
                    '& p': {
                        fontSize: 14,
                        lineHeight: 1.5,
                        marginBottom: 32,
                        [theme.breakpoints.up('md')]: {
                            letterSpacing: '0.032em',
                        },
                    },
                    '& .cta': {
                        fontFamily: theme.headerFont,
                        color: theme.palette.primary.main,
                        fontSize: 16,
                        lineHeight: 1.3,
                        letterSpacing: '0.03em',
                        borderBottom: '2px solid',
                        borderBottomColor: theme.palette.primary.main,
                        textTransform: 'uppercase',
                    }
                },
                '&::after': {
                    clear: 'both',
                    display: 'table',
                    content: '""',
                }
            }
        },
        '& ul.MuiPagination-ul': {
            justifyContent: 'center',
        }
    },
    blogPagePostsTheme: {
        ...theme.blogPagePostsTheme,
    }
})