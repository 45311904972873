import React, { useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../Link"
import Button from "@material-ui/core/Button"
import DragScroll from "react-dragscroll"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CgbAccommodationFeaturedRoomsInnerBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query AccommodationFeaturedRoomsList {
      wp {
        rooms(first: 100) {
          nodes {
            RoomData {
              featured
              featuredImage {
                mediaItemUrl
                mediaItemUrlSharp {
                  id
                  publicURL
                  childImageSharp {
                    fluid(quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            id
            roomType
            title
            slug
          }
        }
        themeOptions {
          accommodationPath
        }
      }
    }
  `)

  var types = []
  var featured = []

  data.wp.rooms.nodes.map(room => {
    if (room.RoomData.featured === true) {
      if (!types.includes(room.roomType)) {
        featured.push(room)
        types.push(room.roomType)
      }
    }
    return 0
  })

  const [active, setActive] = useState(featured[0].roomType)

  return (
    <div
      className={`featured-rooms ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.accommodationFeaturedRoomsInner} ${
        classes.accommodationFeaturedRoomsInnerTheme
      }`}
    >
      <div className="scrollable">
        <DragScroll className="scroll-inner" height={72} width={100}>
          {types.map(type => {
            return (
              <Button
                data-active={type === active ? true : false}
                onClick={() => setActive(type)}
                key={type}
              >
                {type}
              </Button>
            )
          })}
        </DragScroll>
      </div>
      <div className="featured-rooms-list">
        {featured.map(room => {
          return (
            <div
              className="room"
              data-active={room.roomType === active ? true : false}
              key={room.id}
            >
              {room.RoomData.featuredImage &&
              room.RoomData.featuredImage.mediaItemUrlSharp ? (
                <Link
                  to={
                    "/" +
                    data.wp.themeOptions.accommodationPath +
                    "/" +
                    room.slug +
                    "/"
                  }
                  style={{
                    backgroundImage:
                      "url(" +
                      room.RoomData.featuredImage.mediaItemUrlSharp.publicURL +
                      ")",
                  }}
                >
                  <span>{props.attributes.btnText}</span>
                </Link>
              ) : (
                <Link
                  to={
                    "/" +
                    data.wp.themeOptions.accommodationPath +
                    "/" +
                    room.slug +
                    "/"
                  }
                  style={{
                    backgroundImage: `url(${
                      room.RoomData.featuredImage !== null
                        ? room.RoomData.featuredImage.mediaItemUrl
                        : ""
                    })`,
                  }}
                >
                  <span>{props.attributes.btnText}</span>
                </Link>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

CgbAccommodationFeaturedRoomsInnerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbAccommodationFeaturedRoomsInnerBlockFragment = graphql`
  fragment CgbAccommodationFeaturedRoomsInnerBlock on wp_Block {
    ... on wp_CgbAccommodationFeaturedRoomsInnerBlock {
      name
      attributes {
        __typename
        ... on wp_CgbAccommodationFeaturedRoomsInnerBlockAttributes {
          className
          btnText
        }
      }
    }
  }
`

export const CgbAccommodationFeaturedRoomsInnerBlockPreview = `
... on CgbAccommodationFeaturedRoomsInnerBlock {
  name
  attributes {
    __typename
    ... on CgbAccommodationFeaturedRoomsInnerBlockAttributes {
      className
      btnText
    }
  }
}
`
