import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { buildBlocks } from "../../../services/builder"

export const AcfTimedContainerBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  // console.log(props);

  // var dateFormat = require("dateformat");

  var now = new Date()
  var start = new Date()
  var end = new Date()
  if (props.block.blockTimedContent.startDate !== null) {
    start = new Date(props.block.blockTimedContent.startDate.replace(/-/g, "/"))
  }
  if (props.block.blockTimedContent.endDate !== null) {
    end = new Date(props.block.blockTimedContent.endDate.replace(/-/g, "/"))
  }

  // console.log(now, start, end);

  // const [now] = React.useState( new Date() );
  // const [start] = React.useState( startDate );
  // const [end] = React.useState( endDate );

  // if ( props.block.blockTimedContent.endDate != null && ( buildDate > endDate ) ) {
  //   return null;
  // } else
  if (
    (props.block.blockTimedContent.startDate == null &&
      props.block.blockTimedContent.endDate == null) ||
    (props.block.blockTimedContent.startDate != null &&
      props.block.blockTimedContent.endDate == null &&
      now > start) ||
    (props.block.blockTimedContent.endDate != null &&
      props.block.blockTimedContent.startDate == null &&
      now < end) ||
    (props.block.blockTimedContent.startDate != null &&
      props.block.blockTimedContent.endDate != null &&
      now > start &&
      now < end)
  ) {
    return (
      <>
        {blocks.map(block => {
          return block
        })}
      </>
    )
  } else {
    return null
  }
}

AcfTimedContainerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfTimedContainerBlockFragment = graphql`
  fragment AcfTimedContainerBlock on wp_Block {
    ... on wp_AcfTimedContainerBlock {
      name
      attributes {
        className
      }
      blockTimedContent {
        startDate
        endDate
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTimedContainerBlockPreview = `
... on AcfTimedContainerBlock {
  name
  attributes {
      className
  }
  blockTimedContent {
    startDate
    endDate
  }
}
`
