export default theme => ({
    accommodationBanner: {
        position: 'relative',
        marginBottom: 36,
        '& .desktop-bg': {
            display: 'none !important',
            [theme.breakpoints.up('md')]: {
                display: 'block !important',
            },
        },
        '& .mobile-bg': {
            display: 'block !important',
        },
        [theme.breakpoints.up('md')]: {
            '& .mobile-bg': {
                display: 'none !important',
            }
        },
        '& > .gatsby-image-wrapper': {
            height: '595px !important',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px) !important',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '50% !important',
                }
            },
            '&::before': {
                position: 'absolute',
                display: 'block',
                content: '""',
                top: '0',
                left: '0',
                right: '0',
                height: 52,
                zIndex: '2',
                background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.84) 37%,rgba(0,0,0,0) 100%)',
                opacity: '0.3',
                [theme.breakpoints.up('md')]: {
                    height: 115,
                    opacity: '0.4',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 33%,rgba(0,0,0,0) 100%)',
                },
            },
            '&::after': {
                position: 'absolute',
                display: 'block',
                content: '""',
                bottom: '0',
                left: '0',
                right: '0',
                height: 300,
                zIndex: '2',
                background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%,rgba(0,0,0,1) 100%)',
                opacity: '0.3',
                [theme.breakpoints.up('md')]: {
                    height: 340,
                    opacity: '0.51',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.58) 33%,rgba(0,0,0,1) 66%,rgba(0,0,0,1) 100%)',
                },
            },
        },
        '& .banner-content' : {
            position: 'absolute',
            bottom: 90,
            color: 'white',
            marginBottom: '0',
            padding: '15px 0',
            zIndex: '2',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '75%'
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '50%',
                bottom: 50,
            },
            '& h1, & h2, & h3, & h4, & h5, & h6' : {
                marginBottom: 0,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 10,
                }
            },
            '& .extras' : {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'block',
                    fontSize: 12,
                    lineHeight: 1.2,
                    letterSpacing: '0.032em',
                    '& span': {
                        display: 'block',
                        fontWeight: '600',
                        fontSize: 38,
                        letterSpacing: '-0.008em',
                    }
                }
            }
        },
    },
    accommodationBannerTheme: {
        ...theme.accommodationBannerTheme,
    }
})