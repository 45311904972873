export default theme => ({
    cardBlock: {
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: 6,
        boxShadow: '0 4px 6px rgba(0,0,0,0.16)',
        display: 'block',
        textDecoration: 'none !important',
        marginBottom: 36,
        '& .gatsby-image-wrapper': {
            height: 'auto !important',
            '& > div': {
                paddingBottom: '68% !important',
            },
        },
        '& .con-wrap': {
            padding: 30,
            '& .min-height': {
                minHeight: 150,
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                fontSize: 18,
                marginBottom: 16,
                letterSpacing: '0.003em',
                color: theme.palette.text.primary,
            },
            '& p': {
                fontSize: 14,
                lineHeight: '2',
                marginBottom: 40,
                color: theme.palette.text.primary,
            },
            '& .cta': {
                color: theme.palette.primary.main,
                fontSize: 18,
                fontFamily: theme.headerFont,
                lineHeight: 1.2,
                whiteSpace: "nowrap",
                borderBottom: '2px solid',
                letterSpacing: '0.06em',
                textTransform: 'uppercase',
                borderBottomColor: theme.palette.primary.main,
                textDecoration: 'none',
                transition: 'color .3s ease-in-out, border-bottom-color .3s ease-in-out',
                '&:hover, &:focus': {
                    borderBottomColor: theme.palette.primary.dark,
                    color: theme.palette.primary.dark,
                }
            }
        }
    },
    cardBlockTheme: {
        ...theme.cardBlockTheme,
    }
})