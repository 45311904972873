export default theme => ({
    accommodationIntro: {
        position: 'relative',
        // padding: '24px 0',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            // padding: '110px 0 94px',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            marginBottom: 20,
            letterSpacing: '0.06em',
        },
        '& p': {
            lineHeight: '1.8',
            marginBottom: 0,
        },
        '& .extras' : {
            display: 'block',
            fontSize: 12,
            lineHeight: 1.2,
            letterSpacing: '0.032em',
            marginBottom: 50,
            '& span': {
                display: 'block',
                fontWeight: '600',
                fontSize: 38,
                letterSpacing: '-0.008em',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        '& ul': {
            margin: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
                columnCount: 2,
            },
            '& li': {
                display: 'block',
                fontSize: 16,
                lineHeight: '1.5',
                marginBottom: 10,
                letterSpacing: '0.032em',
                [theme.breakpoints.up('md')]: {
                    marginBottom: 15,
                },
                '& .gatsby-image-wrapper': {
                    width: 24,
                    display: 'inline-block',
                    marginRight: 10,
                    verticalAlign: 'middle',
                    '& > div': {
                        paddingBottom: '24px !important',
                    }
                }
            },
        },
        '& .MuiGrid-root': {
            justifyContent: 'space-between',
        }
    },
    accommodationIntroTheme: {
        ...theme.accommodationIntroTheme,
    }
})