export default theme => ({
    calloutCta: {
        position: 'relative',
        backgroundColor: theme.palette.tertiary.main,
        marginBottom: 36,
        padding: '36px 0',
        width: '100vw',
        left: '50%',
        marginLeft: '-50vw',
        [theme.breakpoints.up('md')]: {
            padding: '72px 0',
        },
        '& p': {
            fontSize: 18,
            lineHeight: 1.4,
            marginBottom: 0,
            backgroundColor: theme.palette.tertiary.contrastText,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
            },
        },
        '& button': {
            width: '100%',
        },
        '& > div': {
            maxWidth: 1120,
            margin: '0 auto',
            width: 'calc(100% - 24px)',
        }
    },
    calloutCtaTheme: {
        ...theme.calloutCtaTheme,
    }
})