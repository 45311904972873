import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button/Button"
import Link from "../../Link"
import axios from "axios"
import Typography from "@material-ui/core/Typography"
import ReactRecaptcha from "react-recaptcha"
import WarningIcon from "@material-ui/icons/Warning"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { trackContactForm } from "../../../services/gtm"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const CgbSignupGateFormBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  const data = useStaticQuery(graphql`
    query signupGateHouseInfo {
      wp {
        locationData {
          LocationData {
            crmId
            name
          }
        }
      }
    }
  `)
  var crmID = data.wp.locationData.LocationData.crmId
  var houseName = data.wp.locationData.LocationData.name
  var { successUrl, successDescription } = props.attributes

  const [formError, setFormError] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSending, setFormSending] = useState(false)

  const [forename, setForename] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [recaptchaResponse, setRecaptchaResponse] = useState("")
  const [validationErrors, setValidationErrors] = useState([])

  const submitForm = event => {
    // send
    setFormSending(true)
    let postData = {
      forename: forename,
      surname: surname,
      email: email,
      house_id: crmID,
      house_name: crmID === null ? "" : houseName,
      source: "Wedding 360 Tour Signup",
      "g-recaptcha-response": recaptchaResponse,
    }

    axios({
      method: "post",
      url: `${process.env.GATSBY_FORM_ENDPOINTS_URL}${process.env.GATSBY_FORM_ENDPOINTS_WEBSITE_NAME}/wedding-360-tour`,
      data: postData,
    })
      .then(response => {
        console.log("Endpoint response", response)
        if (Boolean(response.data.success)) {
          trackContactForm()
          setFormSubmitted(true)
          window.open(successUrl, "_blank")
        }
        setFormSending(false)
      })
      .catch(error => {
        console.log("Endpoint error", error)
        let errorMessage =
          "There was a problem submitting your details. Please try again later."
        if (error.hasOwnProperty("response")) {
          if (error.response) {
            console.log("Response", error.response)
            if (error.response.data.hasOwnProperty("message")) {
              errorMessage = error.response.data.message
              setValidationErrors([])
            } else if (error.response.data.hasOwnProperty("validation")) {
              setValidationErrors(error.response.data.validation)
              errorMessage = "Please check the form for errors."
            }
          } else if (error.request) {
            console.log("Request", error.request)
          } else {
            console.log("Setup", error.message)
          }
        }
        setFormError(errorMessage)
        setFormSending(false)
      })
  }

  const recaptchaVerified = response => {
    console.log("Recaptcha response", response)
    setRecaptchaResponse(response)
  }

  return (
    <div
      className={`contact-form ${
        props.attributes.className ? props.attributes.className : ""
      } ${useClasses.signupGateForm}`}
    >
      {blocks.map(block => {
        return block
      })}

      <Typography variant="body1">
        See our interactive 360 degree walkthrough
      </Typography>

      {formSubmitted && (
        <div className={useClasses.thanks}>
          <p>
            <CheckCircleIcon /> Thank you for your submission.
          </p>

          <p>
            If a new tab has not opened you can use this link to{" "}
            <a href={successUrl} target="_blank" rel="noreferrer">
              {successDescription}
            </a>
          </p>
        </div>
      )}

      {!formSubmitted && formError.length !== 0 && (
        <p className={useClasses.errors}>
          <WarningIcon /> {formError}
        </p>
      )}
      {!formSubmitted && (
        <>
          <form>
            <Grid container spacing={3} direction="row" justify="center">
              <Grid item xs={12} md={5} lg={3}>
                <label htmlFor="f-name" className={useClasses.label}>
                  First Name
                </label>
                <TextField
                  id="f-name"
                  variant="outlined"
                  className={`${useClasses.inputs} ${
                    validationErrors.forename ? "has-error" : ""
                  }`}
                  placeholder={
                    props.pageContext.wedding ? "First Name" : "Frederic"
                  }
                  onChange={e => setForename(String(e.target.value))}
                  aria-required={true}
                  aria-invalid={Boolean(validationErrors.forename)}
                />
                {validationErrors.forename && (
                  <p className={useClasses.validationError}>
                    {validationErrors.forename}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <label htmlFor="s-name" className={useClasses.label}>
                  Last Name
                </label>
                <TextField
                  id="s-name"
                  variant="outlined"
                  className={`${useClasses.inputs} ${
                    validationErrors.surname ? "has-error" : ""
                  }`}
                  placeholder={
                    props.pageContext.wedding ? "Last Name" : "Robinson"
                  }
                  onChange={e => setSurname(String(e.target.value))}
                />
                {validationErrors.surname && (
                  <p className={useClasses.validationError}>
                    {validationErrors.surname}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <label htmlFor="email" className={useClasses.label}>
                  Email Address
                </label>
                <TextField
                  id="email"
                  variant="outlined"
                  className={`${useClasses.inputs} ${
                    validationErrors.email ? "has-error" : ""
                  }`}
                  placeholder={
                    props.pageContext.wedding
                      ? "Email"
                      : "Frederic.Robinson@email.com"
                  }
                  onChange={e => setEmail(String(e.target.value))}
                />
                {validationErrors.email && (
                  <p className={useClasses.validationError}>
                    {validationErrors.email}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                {/*<ReactRecaptcha className={useClasses.recaptcha}
                                        sitekey={process.env.GATSBY_GRECAPTCHA_KEY}
                                        render="explicit"
                                        verifyCallback={recaptchaVerified}
                        />*/}
                <Button
                  variant="contained"
                  color="primary"
                  className={useClasses.button}
                  disabled={formSending}
                  onClick={event => submitForm(event)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <p className={useClasses.disclaimer}>
        See our <Link to="/privacy-policy/">Privacy Policy</Link> for full
        details of how we process your personal data.
      </p>
    </div>
  )
}

export const CgbSignupGateFormBlockFragment = graphql`
  fragment CgbSignupGateFormBlock on wp_Block {
    ... on wp_CgbSignupGateFormBlock {
      name
      attributes {
        __typename
        ... on wp_CgbSignupGateFormBlockAttributes {
          className
          successDescription
          successUrl
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSignupGateFormBlockPreview = `
    ... on CgbSignupGateFormBlock {
        name
        attributes {
          className
          successDescription
          successUrl
        }
    }
`
