import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { transformLink } from '../../../helpers';
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => ({
    gallery: {
        position: 'relative',
        margin: '0 -8px !important',
        width: 'calc(100% + 16px)',
        '& li': {
            float: 'left',
            padding: '0 8px !important',
        }
    },
}));

export const CoreGalleryBlock = (props) => {
    const classes = useStyles();
    if( ! props.attributes) return false;
    return <GridList 
      cellHeight={'auto'} 
      className={`${props.attributes.className ? props.attributes.className : ''} ${classes.gallery}`}
      cols={props.attributes.columns ? props.attributes.columns : 2 }
    >
      
    {props.attributes.images && props.attributes.images.map((tile) => (
      <GridListTile key={tile.id}>
        {tile.fullUrlSharp ? 
          (tile.fullUrlSharp.childImageSharp ? 
            <Img fluid={tile.fullUrlSharp.childImageSharp.fluid} alt={tile.alt} />
            : 
            <img src={tile.fullUrlSharp.publicURL} alt={tile.alt}/>
          )
        :
            <div className="gatsby-image-wrapper">
                <div style={{ paddingBottom: '100%' }} />
                <img
                    src={tile.url}
                    alt={tile.alt}
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                />
            </div>
        }
        
        {tile.caption ? 
            <GridListTileBar
              title={tile.caption}
            >
                {ReactHtmlParser(tile.caption, {transform : transformLink})}
            </GridListTileBar>
        : ''}
      </GridListTile>
    ))}
  </GridList>
};

CoreGalleryBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreGalleryBlockFragment = graphql`
  fragment CoreGalleryBlock on wp_Block {
    ...on wp_CoreGalleryBlock {
      name
      attributes {
        __typename
        ...on wp_CoreGalleryBlockAttributes {
          align
          caption
          className
          columns
          ids
          imageCrop
          images {
            alt
            caption
            fullUrl
            id
            link
            url
            fullUrlSharp {
              id
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }              
          }
          linkTo
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreGalleryBlockPreview = `
...on CoreGalleryBlock {
  name
  attributes {
    __typename
    ...on CoreGalleryBlockAttributes {
      align
      caption
      className
      columns
      ids
      imageCrop
      images {
        alt
        caption
        fullUrl
        id
        link
        url
      }
      linkTo
    }
  }
}
`;