export default theme => ({
  videoIntro: {
    position: "relative",
    marginBottom: 40,
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 37,
      marginTop: 0,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
        marginBottom: 52,
      },
    },
  },
})
