import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfTwoColumnStackedBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`two-column-stacked ${useClasses.twoColumnStacked}`}>
      {blocks.map(block => {
        return block
      })}
    </div>
  )
}

export const AcfTwoColumnStackedBlockFragment = graphql`
  fragment AcfTwoColumnStackedBlock on wp_Block {
    ... on wp_AcfTwoColumnStackedBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTwoColumnStackedBlockPreview = `
    ... on AcfTwoColumnStackedBlock {
        name
    }
`
