import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {graphql, useStaticQuery} from "gatsby";
import Typography from "@material-ui/core/Typography/Typography";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import dateFormat from 'dateformat';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbOpeningTimesBlock = (props) => {
    // const today = dateFormat(new Date(), 'dddd').toLowerCase();

    const [active, setActive] = useState('opening');
    
    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query openingTimes {
        wp {
          locationData {
            LocationData {
              timesJson
            }
          }
        }
      }
    `);

    /*
    * This section is used to force the client side to render the component. Without it, the user is shown
    * the server-side generated content which means the opening and food service times shown will be the 
    * times for whichever day of the week the site was built - and not today's times
    */
    const [hasMounted, setHasMounted] = useState(false);

    React.useEffect(() => {
        setHasMounted(true);
    }, [])

    if (!hasMounted) {
        return null;
    }
    /* -- End -- */

    const allTimes = JSON.parse(data.wp.locationData.LocationData.timesJson);
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
        }

    return (
        <div className={`opening-times ${props.attributes.className ? props.attributes.className : ''} ${classes.openingTimes} ${classes.openingTimesTheme}`}>
            <div className="inner">
                <div className="title">
                    <Typography component='h2' variant='h2' color='initial' align='left'>Opening & Serving Times</Typography>
                </div>
                <div className="times">
                    <div className="select">
                        <p>Select a session</p>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={active}
                                onChange={(e) => setActive(String(e.target.value))}
                                MenuProps={{ disableScrollLock: true }}
                            >
                                <MenuItem value='opening' style={{marginBottom: 0}}>Opening</MenuItem>
                                {allTimes.hasOwnProperty('food') &&
                                    <MenuItem value='food' style={{marginBottom: 0}}>Food</MenuItem>
                                }
                                {allTimes.hasOwnProperty('breakfast') &&
                                    <MenuItem value='breakfast' style={{marginBottom: 0}}>Breakfast</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="time-types">
                        {allTimes.hasOwnProperty('opening') && days.map(function (day, index) {
                            let content = null;
                            // console.log(allTimes.opening[day])
                            if (allTimes.opening[day]) {
                                content = allTimes.opening[day].map((time, timeIndex) => {
                                    return <span key={'time-' + timeIndex}>{time.open} - {time.close}</span>
                                });
                            } else {
                                content = <span>Closed</span>
                            }
                            return <div key={'opening-' + day} className="time-block" data-active={'opening' === active}>
                                <span className="type">{capitalizeFirstLetter(day)}</span>
                                <span className="breakdown">{content}</span>
                            </div>
                        })}

                        {allTimes.hasOwnProperty('breakfast') && days.map(function (day, index) {
                            let content = null;
                            // console.log(allTimes.breakfast[day])
                            if (allTimes.breakfast[day]) {
                                content = allTimes.breakfast[day].map((time, timeIndex) => {
                                    return <span key={'time-' + timeIndex}>{time.open} - {time.close}</span>
                                });
                            } else {
                                content = <span>Not Served</span>
                            }
                            return <div key={'breakfast-' + day} className="time-block" data-active={'breakfast' === active}>
                                <span className="type">{capitalizeFirstLetter(day)}</span>
                                <span className="breakdown">{content}</span>
                            </div>
                        })}

                        {allTimes.hasOwnProperty('food') && days.map(function (day, index) {
                            let content = null;
                            // console.log(allTimes.food[day])
                            if (allTimes.food[day]) {
                                content = allTimes.food[day].map((time, timeIndex) => {
                                    return <span key={'time-' + timeIndex}>{time.open} - {time.close}</span>
                                });
                            } else {
                                content = <span>Not Served</span>
                            }
                            return <div key={'food-' + day} className="time-block" data-active={'food' === active}>
                                <span className="type">{capitalizeFirstLetter(day)}</span>
                                <span className="breakdown">{content}</span>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )


};

CgbOpeningTimesBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbOpeningTimesBlockFragment = graphql`
  fragment CgbOpeningTimesBlock on wp_Block {
    ... on wp_CgbOpeningTimesBlock {
      name
      attributes {
        __typename
        ... on wp_CgbOpeningTimesBlockAttributes {
            className
        }
      }
    }
  }`;

export const CgbOpeningTimesBlockPreview = `
... on CgbOpeningTimesBlock {
  name
  attributes {
    __typename
    ... on CgbOpeningTimesBlockAttributes {
      className
    }
  }
}
`;
