export default theme => ({
    list: {
        display: 'block',
        position: 'relative',
        marginBottom: 36,
        
        '& ul, & ol': {
            marginTop: 24,
        }
    },   
})