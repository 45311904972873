export default theme => ({
    accommodationFeaturedRoomsInner: {
        position: 'relative',
        '& .scrollable': {
            width: 'calc(100vw - 24px)',
            overflow: 'hidden',
            height: 44,
            [theme.breakpoints.up('md')]: {
                maxWidth: 1120,
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingLeft: '8.333%',
                height: 49,
            },
            '& .scroll-inner': {
                width: '100% !important;',
                whiteSpace: 'nowrap',
                '& button' : {
                    padding: '13px 26px',
                    borderBottom: '2px solid transparent',
                    fontSize: 14,
                    lineHeight: '1.2',
                    letterSpacing: '0.09em',
                    textTransform: 'uppercase',
                    opacity: '0.5',
                    borderRadius: 0,
                    color: theme.palette.primary.main,
                    [theme.breakpoints.up('md')]: {
                        fontSize: 18,
                    },
                    '&[data-active="true"]': {
                        borderBottomColor: theme.palette.primary.main,
                        opacity: '1',
                    },
                }
            }
        },
        '& .featured-rooms-list': {
            '& .room' : {
                display: 'none',
                '&[data-active="true"]': {
                    display: 'block',
                },
            },
           '& .room a': {
               display: 'flex',
               height: 245,
               padding: 24,
               alignItems: 'flex-end',
               justifyContent: 'center',
               backgroundPosition: 'center',
               backgroundSize: 'cover',
               textDecoration: 'none',
               width: '100vw',
               margin: '0 -24px',
               [theme.breakpoints.up('md')]: {
                   width: 'calc(100vw - 160px)',
                   height: 640,
                   padding: 60,
                   justifyContent: 'flex-end',
                   alignItems: 'center',
               },
               '& span': {
                   color: 'white',
                   textShadow: '0 3px 9px rgba(0,0,0,0.42)',
                   fontFamily: theme.headerFont,
                   fontSize: 18,
                   lineHeight: 1.4,
                   letterSpacing: '0.03em',
                   textTransform: 'uppercase',
                   '&::after': {
                       content: '""',
                       display: 'block',
                       height: 4,
                       borderRadius: 2,
                       backgroundColor: 'white',
                       boxShadow: '0 3px 9px rgba(0,0,0,0.42)',
                       marginTop: 3,
                   }
               }
           }
        }
    },
    accommodationFeaturedRoomsInnerTheme: {
        ...theme.accommodationFeaturedRoomsInnerTheme,
    }
})