import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbCalloutCtaBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    // console.log(blocks)

    return <div className={`callout-cta ${props.attributes.className ? props.attributes.className : ''} ${classes.calloutCta} ${classes.calloutCtaTheme}`}>
        <Grid container spacing={3} direction="row" justiy="center" alignItems="center">
            {blocks[0].props.block.name === "core/image" &&
                <Grid item xs={12} md={7} lg={6} >
                    {blocks[1]}
                </Grid>
            }
            {blocks[0].props.block.name !== "core/image" &&
                <Grid item xs={12} md={7} lg={6} >
                    {blocks[0]}
                </Grid>
            }
            <Grid item xs={false} md={1} lg={3} />
            {blocks[0].props.block.name === "core/image" &&
                <Grid item xs={12} md={4} lg={3} className="image-wrap">
                    {blocks[2]}
                </Grid>
            }
            {blocks[0].props.block.name !== "core/image" &&
                <Grid item xs={12} md={4} lg={3} className="image-wrap">
                    {blocks[1]}
                </Grid>
            }
        </Grid>
    </div>
};

export const CgbCalloutCtaBlockFragment = graphql`
  fragment CgbCalloutCtaBlock on wp_Block {
    ... on wp_CgbCalloutCtaBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCalloutCtaBlockPreview = `
    ... on CgbCalloutCtaBlock {
        name
        attributes {
            className
        }
    }
`;