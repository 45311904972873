import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import InnsListingCrm from './InnsListingCrm';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbPubListingBlock = (props) => {
    const classes = useStyles();

    return <div className={`pub-listing ${props.attributes.className ? props.attributes.className : ''} ${classes.pubListing} ${classes.pubListingTheme}`}>
        {props.attributes.innsListing === true &&
            <InnsListingCrm />
        }
    </div>
};

export const CgbPubListingBlockFragment = graphql`
  fragment CgbPubListingBlock on wp_Block {
    ... on wp_CgbPubListingBlock {
        name
        attributes {
            className
            innsListing
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbPubListingBlockPreview = `
    ... on CgbPubListingBlock {
        name
        attributes {
            className
            innsListing
        }
    }
`;