import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  awardRow: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    padding: "32px 18px",
    width: "100vw",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    position: "relative",
    zIndex: 10,
    marginBottom: -24,
    [theme.breakpoints.up("md")]: {
      gap: "40px",
      padding: "80px 18px",
    },
    "& img": {
      width: 50,
      height: 50,
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.up("md")]: {
        width: 120,
        height: 120,
      },
    },
  },
}))

export const AcfAwardRowBlock = props => {
  const classes = useStyles()
  let images = props.block.blockAwardRow.awards

  return (
    <div
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.awardRow}`}
    >
      {images.map(image => (
        <img
          src={
            image.mediaItemUrlSharp
              ? image.mediaItemUrlSharp.publicURL
              : image.mediaItemUrl
          }
          alt={image.altText}
        />
      ))}
    </div>
  )
}

AcfAwardRowBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfAwardRowBlockFragment = graphql`
  fragment AcfAwardRowBlock on wp_Block {
    ... on wp_AcfAwardRowBlock {
      name
      attributes {
        className
      }
      blockAwardRow {
        awards {
          id
          altText
          mediaItemUrlSharp {
            id
            publicURL
          }
          mediaDetails {
            height
            width
          }
          mediaItemUrl
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfAwardRowBlockPreview = `
... on AcfAwardRowBlock { 
  name 
  attributes { 
    className 
  } 
  blockAwardRow { 
    awards { 
      id
      altText
      mediaItemUrl 
    } 
  } 
}`
