import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import weddingStyles from "./weddingStyles"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfTextBackgroundBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  return (
    <div className={`text-background ${useClasses.textBackground}`}>
      {blocks[0]}
    </div>
  )
}

export const AcfTextBackgroundBlockFragment = graphql`
  fragment AcfTextBackgroundBlock on wp_Block {
    ... on wp_AcfTextBackgroundBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTextBackgroundBlockPreview = `
... on AcfTextBackgroundBlock {
  name
}
`
