import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import { transformLink } from '../../../helpers/';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    quote: {
        display: 'block',
        position: 'relative',
        marginBottom: 36,
    },
}));

export const CgbQuoteBlock = (props) => {
    const classes = useStyles();

    return <div className={`quote-block ${props.attributes.className ? props.attributes.className : ''} ${classes.quote}`}>
        <div className="quote-text">
          {ReactHtmlParser(props.attributes.value, {transform : transformLink})}
        </div>
        <Typography
          component="p"
          variant="body1"
          className={props.attributes.className ? props.attributes.className : ''}
          paragraph={true}
      >
          {ReactHtmlParser(props.attributes.citation, {transform : transformLink})}
      </Typography>
    </div>
};

CgbQuoteBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbQuoteBlockFragment = graphql`
  fragment CgbQuoteBlock on wp_Block {
    ...on wp_CgbQuoteBlock {
      name
      attributes {
        __typename
        ...on wp_CgbQuoteBlockAttributes {
          citation
          className
          value            
        }
      }
    }
  }`;

  export const CgbQuoteBlockPreview = `
  ...on CgbQuoteBlock {
    name
    attributes {
      __typename
      ...on CgbQuoteBlockAttributes {
        citation
        className
        value            
      }
    }
  }
  `;