export default theme => ({
  bannerMessage: {
    position: "relative",
    marginBottom: 40,
    "& .desktop-bg": {
      display: "none !important",
      [theme.breakpoints.up("md")]: {
        display: "block !important",
      },
    },
    "& .mobile-bg": {
      display: "block !important",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
      "& .mobile-bg": {
        display: "none !important",
      },
    },
    "& > .gatsby-image-wrapper": {
      height: "796px !important",
      marginLeft: "50%",
      transform: "translateX(-50%)",
      maxWidth: "100vw",
      width: "calc(100% + 50px) !important",
      zIndex: "1",
      [theme.breakpoints.up("md")]: {
        width: "100vw !important",
        "& > div": {
          paddingBottom: "50% !important",
        },
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      fontSize: "62px !important",
      textAlign: "center",
      maxWidth: 330,
      top: 290,
      position: "absolute",
      width: "calc(100% - 60px)",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1,
      color: "white !important",
      fontWeight: "normal !important",
      lineHeight: 0.9,
      [theme.breakpoints.up("md")]: {
        top: 230,
        fontSize: "107px !important",
        maxWidth: 480,
        lineHeight: 1,
      },
    },
  },
  bannerMessageTheme: null,
  weddingCTAs: {
    display: "grid",
    position: "absolute",
    padding: "20px 12px 40px",
    gap: "12px",
    gridTemplateColumns: "1fr 1fr",
    zIndex: 1,
    top: 796,
    left: "50%",
    width: "100vw",
    transform: "translateY(-100%) translateX(-50%)",
    transition: "transform .3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      maxWidth: 1563,
      padding: "24px 32px 52px",
    },
    [theme.breakpoints.up("lg")]: {
      gap: "90px",
    },
    "&.scrolled": {
      position: "fixed",
      transform: "translateY(0) translateX(-50%)",
      top: 0,
    },
    "& a": {
      fontFamily: "Libre Baskerville",
      fontWeight: "normal",
      color: "white",
      backgroundColor: theme.palette.wedding.button,
      fontSize: 15,
      textAlign: "center",
      borderRadius: 6,
      textDecoration: "none",
      padding: 6,
      boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
      [theme.breakpoints.up("md")]: {
        fontSize: 17,
        padding: 14,
      },
    },
  },
})
