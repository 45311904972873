import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbVideoBannerMessageBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    let poster = {backgroundImage: 'url(' + props.attributes.mediaUrl + ')'}

    if(props.attributes.mediaUrlSharp != null) {
        poster = {backgroundImage: 'url(' + props.attributes.mediaUrlSharp.childImageSharp.fluid.srcWebp + '), url(' + props.attributes.mediaUrlSharp.childImageSharp.fluid.base64 + ')' }
    }

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.bannerMessage} ${classes.bannerMessageTheme}`}
    >
        <div className="gatsby-image-wrapper" style={poster}>
            <div style={{paddingBottom: '50%'}} />
            <div className="video-wrapper">
                <iframe title={"video-banner-"+props.attributes.videoID}
                        src={"https://player.vimeo.com/video/"+props.attributes.videoID+"?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=1&muted=1&background=1"}
                        frameBorder="0"
                        allowFullScreen
                />
            </div>
        </div>
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

CgbVideoBannerMessageBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbVideoBannerMessageBlockFragment = graphql`
    fragment CgbVideoBannerMessageBlock on wp_Block {
        ... on wp_CgbVideoBannerMessageBlock {
            name
            attributes {
                __typename
                ... on wp_CgbVideoBannerMessageBlockAttributes {
                    className
                    videoID
                    mediaUrl
                    mediaUrlSharp {
                        id
                        publicURL
                        childImageSharp {
                            fluid(quality: 50, maxWidth: 1660) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbVideoBannerMessageBlockPreview = `
    ... on CgbVideoBannerMessageBlock {
        name
        attributes {
            __typename
            ... on CgbVideoBannerMessageBlockAttributes {
                className
                videoID
                mediaUrl
            }
        }
    }
`;