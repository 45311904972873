import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigateTo, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import themeOptions from "../../../../.brew-cache/theme.json";
import Link from '../../Link';
import Typography from "@material-ui/core/Typography/Typography";
import Img from "gatsby-image";
import { replaceMasks } from "../../../services/masks";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogPagePostsBlock = (props) => {

    const classes = useStyles();
    /**
     * The blog posts should be passed through from the parent page directly to this component
     * This will only happen on custom page templates like the blog list page 
     * Preview will not have the posts information for this page due to how the query works, so if this not set
     * use some placeholder data to populate the posts
     * */ 
    let { posts, pageNumber, totalPages } = props.pageContext;

    // Toggle output based on Attributes
    // props.attributes.display : list / grid

    if( typeof(posts)==='undefined') {
        // set some sample data
        posts = [
            {
                "node": {
                    title: "Example Post",
                    date: "2020-01-01 00:00:00",
                    excerpt: "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
                    uri: '/',
                    featuredImage: {
                        node: {
                            mediaItemUrl: ""
                        }
                    }
                }
            }
        ]
    }

    // Query out the sticky post
    const data = useStaticQuery(graphql`
      query StickyPostQuery {
        wp {
          posts (where: {isSticky: true}, first: 1) {
            nodes {
              id
              slug
              title
              uri
              featuredImage {
                node {
                  mediaItemUrl
                  mediaItemUrlSharp {
                      id
                      publicURL
                      childImageSharp {
                          fluid(quality: 80) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                }
              }
            }
          } 
        }
      }
    `);

    let featuredSticky = false;
    const featured = {};
    if(data.wp.posts.nodes.length === 1){
        // Use the sticky post first
        featuredSticky = data.wp.posts.nodes[0].id;
        let image = null;

        if(data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp){
            if(data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.childImageSharp){
                image = <Img
                    fluid={data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.childImageSharp.fluid}
                    alt=""
                />
            } else {
                // Gifs do not have a childImageSharp
                image = <img
                    src={data.wp.posts.nodes[0].featuredImage.node.mediaItemUrlSharp.publicURL}
                    alt=""
                />
            }
        } else {
            // This is a preview image
            image = <div className="gatsby-image-wrapper">
                <div style={{paddingBottom: '55%'}} />
                <img
                    src={data.wp.posts.nodes[0].featuredImage.node.mediaUrl}
                    alt=""
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                />
            </div>
        }

        featured.id = data.wp.posts.nodes[0].id;
        featured.uri = data.wp.posts.nodes[0].uri;
        featured.image = image;
        featured.title =  data.wp.posts.nodes[0].title;
    } else {
        if(posts.length > 0){
            let image = null;
            if(posts[0].node.featuredImage.node.mediaItemUrlSharp){
                if(posts[0].node.featuredImage.node.mediaItemUrlSharp.childImageSharp){
                    image = <Img
                        fluid={posts[0].node.featuredImage.node.mediaItemUrlSharp.childImageSharp.fluid}
                        alt={posts[0].node.featuredImage.node.altText}
                    />
                } else {
                    // Gifs do not have a childImageSharp
                    image = <img
                        src={posts[0].node.featuredImage.node.mediaItemUrlSharp.publicURL}
                        alt={posts[0].node.featuredImage.node.altText}
                    />
                }
            } else {
                // This is a preview image
                image = <div className="gatsby-image-wrapper">
                    <div style={{paddingBottom: '55%'}} />
                    <img
                        src={posts[0].node.featuredImage.node.mediaUrl}
                        alt={posts[0].node.featuredImage.node.altText}
                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                    />
                </div>
            }

            featured.id = posts[0].node.id
            featured.uri = posts[0].node.uri
            featured.image = image;
            featured.title =  posts[0].node.title;
        }
    }
    
    return (
        <div className={`cgbBlogPagePostsBlock ${props.attributes.className ? props.attributes.className : ''} ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}>
            <div className="blog-list-banner">
                <Typography component='h1' variant='h1' color='initial' align='left' >{props.attributes.listingTitle}</Typography>
            </div>
            <div className="blog-list">
                <div className="featured-item">
                    <Link to={`/${themeOptions.blogPath}${featured.uri}`}>
                        {featured.image}
                        <div className="cta-wrap">
                            <span className="post-title">{featured.title}</span>
                            <span className="cta">{props.attributes.btnText}</span>
                        </div>
                    </Link>
                </div>
                {posts.map((post, index) => {
                    if((index===0 && featuredSticky===false) || post.node.id===featured.id) return false;
                    let image = null;
                    if(post.node.featuredImage.node.mediaItemUrlSharp){
                        if(post.node.featuredImage.node.mediaItemUrlSharp.childImageSharp){
                            image = <Img
                                fluid={post.node.featuredImage.node.mediaItemUrlSharp.childImageSharp.fluid}
                                alt={post.node.featuredImage.node.altText}
                            />
                        } else {
                            // Gifs do not have a childImageSharp
                            image = <img
                                src={post.node.featuredImage.node.mediaItemUrlSharp.publicURL}
                                alt={post.node.featuredImage.node.altText}
                            />
                        }
                    } else {
                        // This is a preview image
                        image = <div className="gatsby-image-wrapper">
                            <div style={{paddingBottom: '78%'}} />
                            <img
                                src={post.node.featuredImage.node.mediaUrl}
                                alt={post.node.featuredImage.node.altText}
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                            />
                        </div>
                    }
                    return <div className="list-item" key={post.node.id}>
                        {image}
                        <div className="con-wrap">
                          <h2>{post.node.title}</h2>
                          <p>{replaceMasks(post.node.excerpt.replace(/(<([^>]+)>)/gi, ""))}</p>

                          <Link to={`/${themeOptions.blogPath}${post.node.uri}`} style={{ textDecoration: 'none' }} className="cta">
                              {props.attributes.btnText}
                          </Link>
                        </div>
                    </div>
                })}
            </div>
            {totalPages > 1 &&
                <Pagination count={totalPages} page={pageNumber} onChange={(e, value) => { value === 1 ? navigateTo(`/${themeOptions.blogPath}`) : navigateTo(`/${themeOptions.blogPath}/page/${value}`) }}/>
            }
        </div>
    )
};

CgbBlogPagePostsBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbBlogPagePostsBlockFragment = graphql`
  fragment CgbBlogPagePostsBlock on wp_Block {
    ... on wp_CgbBlogPagePostsBlock {
      name
      attributes {
        __typename
        ... on wp_CgbBlogPagePostsBlockAttributes {
            className
            display
            btnText
            listingTitle
        }
      }
    }
  }`;

export const CgbBlogPagePostsBlockPreview = `
... on CgbBlogPagePostsBlock {
  name
  attributes {
    __typename
    ... on CgbBlogPagePostsBlockAttributes {
      className
      display
      btnText
      listingTitle
    }
  }
}
`;