export default theme => ({
    twoColIntro: {
        position: 'relative',
        marginBottom: 36,
        '& .gatsby-image-wrapper': {
            marginBottom: '0',
            [theme.breakpoints.up('md')]: {
                width: '120%',
            },
            '& > div': {
                paddingBottom: '150% !important',
            }
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            bottom: 55,
            left: '50%',
            width: '100vw',
            backgroundColor: theme.palette.tertiary.main,
            transform: 'translateX(-50vw)',
        },
        [theme.breakpoints.up('md')]: {
            '&::before': {
                top: 90,
                bottom: 90,
            },
        },
        '& *': {
            position: 'relative',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            lineHeight: 1.72,
            marginTop: 90,
            marginBottom: 32,
            backgroundColor: theme.palette.tertiary.contrastText,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
            },
        },
        '& p' : {
            marginBottom: 30,
            lineHeight: 1.75,
            backgroundColor: theme.palette.tertiary.contrastText,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
                lineHeight: 2,
            },
        },
        '& button': {
           marginBottom: 40,
            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
            },
        },
        '& .image-wrap': {
            padding: '12px 0',
            [theme.breakpoints.up('md')]: {
                padding: 12,
            },
        }
    },
    towColIntroTheme: {
        ...theme.twoColIntroTheme
    },
})