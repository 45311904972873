import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

import { transformLink } from '../../../helpers/';
import { graphql } from "gatsby";

export const CoreTableBlock = (props) => {

    let header = null;
    let footer = null; 

    if(props.attributes.head.length){
      header = <TableHead>
                {props.attributes.head.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.cells.map((cell, index) => {
                        return <TableCell key={index} align={cell.align ? cell.align : 'left'}>{ReactHtmlParser(cell.content, {transform : transformLink})}</TableCell>
                      })}
                    </TableRow>
                  )
                })}
            </TableHead>
    }

    if(props.attributes.foot.length){
      footer = <TableFooter>
                {props.attributes.head.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.cells.map((cell, index) => {
                        return <TableCell key={index} align={cell.align ? cell.align : 'left'}>{ReactHtmlParser(cell.content, {transform : transformLink})}</TableCell>
                      })}
                    </TableRow>
                  )
                })}
            </TableFooter>
    }

    return <TableContainer style={{ margin: '6px 0', backgroundColor: props.attributes.backgroundColor}}>
    <Table 
      className={`${props.attributes.className ? props.attributes.className : '' } ${props.attributes.backgroundColor ? props.attributes.backgroundColor : ''}`} 
      aria-label="table"
    >

        {header}

        <TableBody>
          {props.attributes.body.map((row, index) => {
              return (
                <TableRow key={index}>
                  {row.cells.map((cell, index) => {
                    return <TableCell key={index} align={cell.align ? cell.align : 'left'}>{ReactHtmlParser(cell.content, {transform : transformLink})}</TableCell>
                  })}
                </TableRow>
              )
          })}
        </TableBody>

        {footer}

    </Table>
  </TableContainer>
};

CoreTableBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreTableBlockFragment = graphql`
  fragment CoreTableBlock on wp_Block {
    ...on wp_CoreTableBlock {
      name
      attributes {
        __typename
        ...on wp_CoreTableBlockAttributes {
          align
          backgroundColor
          body {
            cells {
              align
              content
              scope
              tag
            }
          }
          className
          foot {
            cells {
              align
              content
              scope
              tag
            }
          }
          hasFixedLayout
          head {
            cells {
              align
              content
              scope
              tag
            }
          }
        }
      }
    }
  }`;

  export const CoreTableBlockPreview = `
  ...on CoreTableBlock {
    name
    attributes {
      __typename
      ...on CoreTableBlockAttributes {
        align
        backgroundColor
        body {
          cells {
            align
            content
            scope
            tag
          }
        }
        className
        foot {
          cells {
            align
            content
            scope
            tag
          }
        }
        hasFixedLayout
        head {
          cells {
            align
            content
            scope
            tag
          }
        }
      }
    }
  }
  `;