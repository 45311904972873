export default theme => ({
  introGalleryMixed: {
    position: "relative",
    display: "grid",
    gap: "24px",
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    [theme.breakpoints.up("md")]: {
      gap: "32px",
      display: "grid",
      gridTemplateColumns: "calc(58.333% - 25px) calc(41.666% - 25px)",
      gridTemplateRows: "auto auto 1fr",
      gridColumnGap: "50px",
      gridRowGap: "50px",
      "&.image-right": {
        gridTemplateColumns: "calc(41.666% - 25px) calc(58.333% - 25px)",
        "& .gatsby-image-wrapper": {
          "&:nth-of-type(1)": {
            gridArea: "2 / 2 / 4 / 3",
          },
          "&:nth-of-type(2)": {
            gridArea: "3 / 1 / 4 / 2",
          },
        },
        "& p": {
          gridArea: "2 / 1 / 3 / 2",
        },
      },
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 32,
      [theme.breakpoints.up("md")]: {
        gridArea: "1 / 1 / 2 / 3",
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& p": {
      fontSize: 13,
      lineHeight: 1.33,
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 17,
        gridArea: "2 / 2 / 3 / 3",
      },
    },
    "& .gatsby-image-wrapper": {
      [theme.breakpoints.up("md")]: {
        "&:nth-of-type(1)": {
          gridArea: "2 / 1 / 4 / 2",
        },
        "&:nth-of-type(2)": {
          gridArea: "3 / 2 / 4 / 3",
        },
      },
    },
  },
})
