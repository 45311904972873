export default theme => ({
  signupGateForm: {
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      textAlign: "center",
      fontSize: 40,
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& p.MuiTypography-body1": {
      textAlign: "center",
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 48,
        fontSize: 25,
        color: "black",
      },
    },
    "& .MuiGrid-item": {
      paddingInline: "30px !important",
    },
  },
  label: {
    display: "none",
  },
  inputs: {
    width: "100%",
    borderRadius: 6,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 12,
    border: `1px solid ${theme.palette.wedding.accent}`,
    "& div": {
      padding: "0 !important",
    },
    "& input, & textarea": {
      fontFamily: "Libre Baskerville, serif",
      padding: 16,
      fontSize: 12,
      [theme.breakpoints.up("md")]: {
        padding: "20.5px 16px",
        fontSize: 16,
      },
    },
    "& fieldset": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0 !important",
    },
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: "2px solid " + theme.palette.wedding.accent,
      borderBottom: "none",
    },
  },
  button: {
    minWidth: 174,
    display: "block",
    backgroundColor: theme.palette.wedding.button,
    color: "white",
    fontFamily: "Libre Baskerville",
    fontSize: 15,
    fontWeight: "normal",
    padding: 8,
    borderRadius: 10,
    textTransform: "none",
    height: 60,
    margin: "0 auto",
    boxShadow: "none",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.wedding.button,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 17,
      borderRadius: 6,
      padding: 15,
      minWidth: 244,
      marginLeft: "auto",
      marginRight: 0,
      display: "inherit",
    },
  },
  errors: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.wedding.accent,
    color: theme.palette.wedding.accent,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto 72px",
    [theme.breakpoints.up("md")]: {
      maxWidth: 720,
    },
    "& svg": {
      verticalAlign: "bottom",
      marginRight: 24,
    },
  },
  recaptcha: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  validationError: {
    position: "relative",
    padding: "12px",
    marginTop: "-12px",
    letterSpacing: "0.032em",
    marginBottom: 15,
    backgroundColor: theme.palette.wedding.accent,
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  thanks: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.wedding.accent,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto",
    fontFamily: "Libre Baskerville, serif",
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
    },
    "& p": {
      "& svg": {
        color: theme.palette.wedding.accent,
        verticalAlign: "bottom",
        marginRight: 12,
      },
      "& a": {
        color: theme.palette.wedding.accent,
      },
    },
  },
  disclaimer: {
    display: "block",
    position: "relative",
    textAlign: "center",
    fontSize: 14,
    padding: 24,
    margin: "0 auto",
    fontFamily: "Libre Baskerville, serif",
    "& a": {
      color: theme.palette.wedding.accent,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 380,
    },
  },
})
