export default theme => ({
  iconRow: {
    position: "relative",
    marginBottom: 40,
    display: "block",
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 37,
      marginTop: 0,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
      },
    },
    "& p": {
      marginBottom: 32,
      fontSize: 13,
      [theme.breakpoints.up("md")]: {
        marginBottom: 40,
        fontSize: 14,
      },
    },
    "& .MuiGrid-grid-xs-7": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
  },
})
