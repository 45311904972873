export default theme => ({
  videoIntro: {
    position: "relative",
    marginBottom: 36,
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 32,
      marginTop: 0,
      textAlign: "center",
    },
  },
})
