import arrow from "../../../images/drop-down.png"

export default theme => ({
  contactForm: {
    position: "relative",
    marginBottom: 36,
    padding: "90px 0",
    [theme.breakpoints.up("md")]: {
      padding: "120px 0",
    },
    "&::before": {
      position: "absolute",
      top: 0,
      left: "50%",
      width: "100vw",
      bottom: 0,
      transform: "translateX(-50vw)",
      backgroundColor: theme.palette.tertiary.main,
      content: '""',
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      color: theme.palette.tertiary.contrastText,
      position: "relative",
      textAlign: "left !important",
      marginBottom: 30,
      [theme.breakpoints.up("md")]: {
        textAlign: "center !important",
        marginBottom: 60,
      },
    },
  },
  contactFormTheme: {
    ...theme.contactFormTheme,
  },
  label: {
    position: "relative",
    display: "block",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: "0.06em",
    fontFamily: theme.headerFont,
    marginBottom: 3,
  },
  inputs: {
    width: "100%",
    border: "none",
    borderRadius: 22,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 12,
    "& div": {
      padding: "0 !important",
    },
    "& input, & textarea": {
      position: "relative",
      border: "none !important",
      backgroundColor: "white",
      fontSize: 12,
      fontFamily: theme.headerFont,
      letterSpacing: "0.01em",
      padding: "15px 20px",
      textTransform: "uppercase",
      height: "auto !important",
      lineHeight: "1.2",
    },
    "& textarea": {
      height: "100px !important",
      [theme.breakpoints.up("md")]: {
        height: "170px !important",
      },
    },
    "& fieldset": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0 !important",
    },
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: "2px solid " + theme.palette.primary.dark,
      borderBottom: "none",
    },
  },
  select: {
    width: "100%",
    border: "none",
    borderRadius: 22,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 12,
    position: "relative",
    border: "none !important",
    backgroundColor: "white",
    fontSize: 12,
    fontFamily: theme.headerFont,
    letterSpacing: "0.01em",
    padding: "15px 20px",
    textTransform: "uppercase",
    height: "auto !important",
    lineHeight: "1.2",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right 10px",
    backgroundImage: `url(${arrow})`,
    backgroundSize: 15,
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: "2px solid " + theme.palette.primary.dark,
      borderBottom: "none",
    },
  },
  button: {
    fontFamily: theme.headerFont,
    padding: "12px 22px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 400,
    textAlign: "center",
    minWidth: 185,
    textTransform: "uppercase",
    margin: "24px auto 0",
    display: "table",
    [theme.breakpoints.up("md")]: {
      margin: "24px 0 0 auto",
    },
  },
  errors: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto 72px",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      maxWidth: 720,
    },
    "& svg": {
      verticalAlign: "bottom",
      marginRight: 24,
    },
  },
  recaptcha: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  validationError: {
    position: "relative",
    padding: "6px 20px",
    marginTop: "-12px",
    marginBottom: 15,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22,
  },
  privacy: {
    color: theme.palette.common.black,
    position: "relative",
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  thanks: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
    },
    "& p": {
      "& svg": {
        verticalAlign: "bottom",
        marginRight: 24,
      },
    },
  },
})
