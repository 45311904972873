import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Grid from "@material-ui/core/Grid"
import styles from "./styles"
import weddingStyles from "./weddingStyles"
import Slider from "react-slick/lib"

const useStyles = makeStyles(theme => styles(theme))
const useWeddingStyles = makeStyles(theme => weddingStyles(theme))

export const AcfTestimonialSliderBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()
  const weddingClasses = useWeddingStyles()

  let useClasses = classes
  if (props.pageContext.wedding) {
    useClasses = weddingClasses
  }

  const settings = {
    dots: true,
    infinite: false,
    centerMode: false,
    // lazyLoad: true,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    // focusOnSelect: true,
  }

  return (
    <div className={`testimonial-slider ${useClasses.testimonialSlider}`}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {blocks[0]}
        </Grid>
      </Grid>
      <Slider {...settings}>
        {blocks.map(block => {
          if (block.props.block.name === "acf/testimonial-slider-inner") {
            return block
          } else {
            return null
          }
        })}
      </Slider>
    </div>
  )
}

export const AcfTestimonialSliderBlockFragment = graphql`
  fragment AcfTestimonialSliderBlock on wp_Block {
    ... on wp_AcfTestimonialSliderBlock {
      name
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfTestimonialSliderBlockPreview = `
... on AcfTestimonialSliderBlock {
  name
}
`
