import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfIssuuBlock = props => {
  const classes = useStyles()

  return (
    <div className={`issuu-embed ${classes.issuEmbed}`}>
      <a id={`#${props.block.blockIssuu.anchor}`} />
      <iframe
        src={`https://e.issuu.com/embed.html?d=${props.block.blockIssuu.issuuId}&u=${props.block.blockIssuu.username}`}
        width="100%"
        height="800"
      ></iframe>
    </div>
  )
}

export const AcfIssuuBlockFragment = graphql`
  fragment AcfIssuuBlock on wp_Block {
    ... on wp_AcfIssuuBlock {
      name
      blockIssuu {
        anchor
        issuuId
        username
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfIssuuBlockPreview = `
... on AcfIssuuBlock {
  name
  blockIssuu {
    anchor
    issuuId
    username
  }
}
`
