export default theme => ({
  introGalleryPortrait: {
    position: "relative",
    marginBottom: 36,
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 24,
      marginTop: 0,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        marginBottom: 60,
      },
    },
    "& p": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        width: "58.333%",
        marginInline: "auto",
        marginBottom: 80,
      },
    },
    "& ul": {
      gap: "26px",
      width: "calc(100% + 34px) !important",
      margin: "0 -17px 32px !important",
      [theme.breakpoints.up("md")]: {
        margin: "0 -17px 72px !important",
        gap: "32px",
      },
      "& li": {
        padding: "0 !important",
        flex: "0 0 calc(50% - 13px)",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(33.333% - 22px) !important",
        },
        "& .gatsby-image-wrapper": {
          "& > div": {
            paddingBottom: "0 !important",
            aspectRatio: "0.76",
            [theme.breakpoints.up("md")]: {
              aspectRatio: 0.63,
            },
          },
        },
        "&:nth-of-type(3)": {
          flex: "0 0 100%",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 calc(33.333% - 22px) !important",
          },
          "& .gatsby-image-wrapper": {
            "& > div": {
              paddingBottom: "0 !important",
              aspectRatio: "1.5",
              [theme.breakpoints.up("md")]: {
                aspectRatio: 0.63,
              },
            },
          },
        },
      },
    },
    "& a": {
      display: "block",
      margin: "0 auto",
      width: "fit-content",
      [theme.breakpoints.up("md")]: {
        marginBottom: 40,
      },
    },
  },
})
