export default theme => ({
    contactBanner: {
        position: 'relative',
        // paddingBottom: 100,
        marginBottom: 36,
        '& .page-banner': {
            padding: '100px 0',
            marginBottom: '-65px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                padding: '200px 0 160px',
                marginBottom: '-78px',
            },
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: theme.palette.secondary.main,
                top: 0,
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50vw)',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                position: 'relative',
                zIndex: '1',
                color: theme.palette.secondary.contrastText,
                marginBottom: 0,
                marginTop: 0,
            }
        },
        '& .map-block': {
            position: 'relative',
            '& .desktop-bg': {
                display: 'none !important',
                [theme.breakpoints.up('md')]: {
                    display: 'block !important',
                },
            },
            '& .mobile-bg': {
                display: 'block !important',
            },
            [theme.breakpoints.up('md')]: {
                '& .mobile-bg': {
                    display: 'none !important',
                }
            },
            '& > .gatsby-image-wrapper': {
                height: '475px !important',
                marginLeft: '50%',
                transform: 'translateX(-50%)',
                maxWidth: '100vw',
                width: 'calc(100% + 50px) !important',
                zIndex: '1',
                '& img': {
                    objectPosition: 'center bottom !important',
                },
                [theme.breakpoints.up('md')]: {
                    height: '560px !important',
                    width: '100% !important',
                    '& img': {
                        objectPosition: 'center right !important',
                    },
                },
            },
            '& .contact-wrap': {
                position: 'absolute',
                zIndex: '1',
                top: 10,
                width: 270,
                left: '50%',
                marginLeft: '-135px',
                [theme.breakpoints.up('md')]: {
                    width: 320,
                    top: 75,
                    left: 120,
                    marginLeft: 0,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 3,
                    boxShadow: '0 4px 7px rgba(0,0,0,0.16)',
                    padding: '60px 45px',
                },
                '& .location-data': {
                    backgroundColor: theme.palette.background.default,
                    padding: '15px 40px',
                    textAlign: 'center',
                    marginBottom: 8,
                    borderRadius: 3,
                    boxShadow: '0 4px 7px rgba(0,0,0,0.16)',
                    [theme.breakpoints.up('md')]: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        marginBottom: 30,
                    },
                    '& p.location-name': {
                        textTransform: 'uppercase',
                        color: theme.palette.secondary.main,
                        marginBottom: 10,
                        fontWeight: '600',
                        marginTop: 0,
                        fontFamily: theme.subHeaderFont,
                        fontSize: 20,
                        padding: '0 30px',
                        letterSpacing: '0.06em',
                        lineHeight: '1.25',
                    },
                    '& p': {
                        color: theme.palette.secondary.main,
                        fontSize: 14,
                        lineHeight: '1.75',
                        margin: 0,
                        '& a': {
                            textDecoration: 'underline',
                            color: theme.palette.secondary.main,
                            fontSize: 12,
                            '&::hover, &::focus': {
                                color: theme.palette.secondary.dark,
                            }
                        }
                    }
                },
                '& a': {
                    display: 'block',
                    color: theme.palette.secondary.main,
                    textDecoration: 'none',
                    backgroundColor: theme.palette.background.default,
                    marginBottom: 8,
                    boxShadow: '0 4px 7px rgba(0,0,0,0.16)',
                    borderRadius: 3,
                    padding: 10,
                    [theme.breakpoints.up('md')]: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        marginBottom: 15,
                    },
                    '& svg': {
                        width: 30,
                        padding: '0 10px 0 0',
                        borderRight: '2px solid rgba(0,0,0,0.25)',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                    },
                    '& span': {
                        display: 'inline-block',
                        width: 'calc(100% - 35px)',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        fontFamily: theme.headerFont,
                    }
                }
            },
            '& .copyright': {
                position: 'absolute',
                bottom: 0,
                right: 0,
                padding: '2px 5px',
                backgroundColor: 'rgba(255,255,255,0.50)',
                fontFamily: 'sans-serif',
                fontSize: 12,
                color: '#333',
                zIndex: '1',
                '& a': {
                    color: '#0078a8 !important',
                }
            }
        }
    },
    contactBannerTheme: {
        ...theme.contactBannerTheme,
    }
})