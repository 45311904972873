import arrow from "../../../images/wedding-drop-down.png"

export default theme => ({
  contactForm: {
    position: "relative",
    marginBottom: 40,
    [theme.breakpoints.up("md")]: {
      marginBottom: 72,
    },
    "& h1, & h2": {
      marginBottom: 32,
      color: theme.palette.wedding.accent,
      fontWeight: "normal",
      fontSize: 40,
      [theme.breakpoints.up("md")]: {
        fontSize: 60,
        lineHeight: "107px",
        marginBottom: 40,
      },
    },
    "& .MuiGrid-spacing-xs-5": {
      margin: 0,
    },
  },
  label: {
    position: "relative",
    display: "block",
    fontSize: 12,
    letterSpacing: "0.06em",
    fontFamily: theme.bodyFont,
    marginBottom: 3,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
  "& .MuiGrid-spacing-xs-5": {
    marging: 0,
  },
  inputs: {
    width: "100%",
    border: "none",
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 12,
    borderBottom: `4px solid ${theme.palette.wedding.accent}`,
    "& div": {
      padding: "0 !important",
    },
    "& input, & textarea": {
      position: "relative",
      border: "none !important",
      backgroundColor: "#D9D9D9",
      fontSize: 12,
      fontFamily: theme.bodyFont,
      letterSpacing: "0.01em",
      padding: "15px 20px",
      height: "auto !important",
      lineHeight: "1.2",
    },
    "& textarea": {
      height: "100px !important",
      [theme.breakpoints.up("md")]: {
        height: "195px !important",
      },
    },
    "& fieldset": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0 !important",
    },
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: "2px solid " + theme.palette.primary.dark,
      borderBottom: "none",
    },
  },
  select: {
    width: "100%",
    border: "none",
    overflow: "hidden",
    marginBottom: 12,
    position: "relative",
    border: "none !important",
    borderBottom: `4px solid ${theme.palette.wedding.accent} !important`,
    backgroundColor: "#D9D9D9",
    fontSize: 12,
    fontFamily: theme.bodyFont,
    letterSpacing: "0.01em",
    padding: "15px 20px",
    height: "auto !important",
    lineHeight: "1.2",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right 10px",
    backgroundImage: `url(${arrow})`,
    backgroundSize: 15,
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: "2px solid " + theme.palette.primary.dark,
      borderBottom: "none",
    },
  },
  button: {
    minWidth: 174,
    backgroundColor: theme.palette.wedding.button,
    color: "white",
    fontFamily: "Libre Baskerville",
    fontSize: 15,
    fontWeight: "normal",
    padding: 8,
    borderRadius: 10,
    boxShadow: "none",
    textTransform: "none",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.wedding.button,
      textTransform: "0 0 4px 4px rgba(0,0,0.25)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 17,
      borderRadius: 6,
      padding: 15,
      minWidth: 244,
      marginLeft: "auto",
      marginRight: 0,
      display: "inherit",
    },
  },
  errors: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto 72px",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      maxWidth: 720,
    },
    "& svg": {
      verticalAlign: "bottom",
      marginRight: 24,
    },
  },
  recaptcha: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  validationError: {
    position: "relative",
    padding: "6px 20px",
    marginTop: "-12px",
    marginBottom: 15,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22,
  },
  privacy: {
    color: theme.palette.common.black,
    position: "relative",
    fontSize: 12,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      textAlign: "left",
      marginBottom: 24,
    },
    "& a": {
      color: theme.palette.wedding.accent,
    },
  },
  thanks: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.wedding.accent,
    textAlign: "left",
    fontSize: 16,
    padding: 24,
    margin: "0 auto",
    fontFamily: "Libre Baskerville, serif",
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
    },
    "& p": {
      "& svg": {
        color: theme.palette.wedding.accent,
        verticalAlign: "bottom",
        marginRight: 12,
      },
      "& a": {
        color: theme.palette.wedding.accent,
      },
    },
  },
})
