export default theme => ({
  iconRow: {
    position: "relative",
    marginBottom: 36,
    paddingTop: 16,
    [theme.breakpoints.up("md")]: {
      display: "block",
      paddingTop: 48,
      paddingBottom: 12,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginBottom: 32,
      marginTop: 0,
      textAlign: 'center',
    },
    "& p": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 40,
      },
    },
    "& .MuiGrid-grid-xs-7": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
  },
})
